export const roundToPrecision = (value: number, digits: 1 | 2 | 3) => {
  const factor = 10 ** digits
  const tooBig = Math.round(value * factor)
  return tooBig / factor
}

export const roundToTenths = (value: number) => roundToPrecision(value, 1)

export const roundToHundreths = (value: number) => roundToPrecision(value, 2)

export const roundToThousandths = (value: number) => roundToPrecision(value, 3)
