import React from 'react'

import {
  convertCssVariables,
  createTheme,
  DEFAULT_THEME,
  defaultCssVariablesResolver,
  MantineProvider,
  mergeMantineTheme,
} from '@mantine/core'

import { colors } from './colors'
import * as components from './components'

// This overrides settings in the default theme which you can see here:
// https://github.com/mantinedev/mantine/blob/master/src/mantine-styles/src/theme/default-theme.ts
const theme = createTheme({
  fontFamily: '"Lato", sans-serif',
  fontFamilyMonospace: '"Courier Prime", courier, monospace',
  headings: {
    fontFamily: '"Montserrat", sans-serif',
    sizes: {
      h1: { fontSize: '32px', lineHeight: '1.3', fontWeight: undefined },
      h2: { fontSize: '24px', lineHeight: '1.35', fontWeight: undefined },
      h3: { fontSize: '20px', lineHeight: '1.4', fontWeight: undefined },
      h4: { fontSize: '16px', lineHeight: '1.45', fontWeight: undefined },
      h5: { fontSize: '14px', lineHeight: '1.5', fontWeight: undefined },
      h6: { fontSize: '12px', lineHeight: '1.5', fontWeight: undefined },
    },
  },
  primaryColor: 'violet',
  primaryShade: { light: 6, dark: 2 },

  colors,
  components,
})

// The entire app gets wrapped in this theme provider, so anything we write automatically
// gets access to the settings configured above.
export const ThemeProvider = ({ children }: { children: React.ReactNode }) => (
  <MantineProvider theme={theme} forceColorScheme="light">
    {children}
  </MantineProvider>
)

/**
 * Builds CSS variables for our app's configured Mantine theme. Based on
 * what's found in Mantine's ThemeProvider, minus the React-specific bits.
 *
 * @param selector used for namespacing the variables
 * @returns CSS as a string, no style tag
 */
export function generateThemeVariables(selector = ':root') {
  const mergedTheme = mergeMantineTheme(DEFAULT_THEME, theme)
  const mergedVariables = defaultCssVariablesResolver(mergedTheme)
  return convertCssVariables(mergedVariables, selector)
}
