import React from 'react'

import { Menu } from '@mantine/core'

import { FancyMenuItem } from '@components/FancyMenuItem'
import { Toolbar } from '@components/Toolbar'
import { ILoadedScript } from '@state'

import {
  getSelFormatBlockName,
  setSpacingAttr,
  VALID_LINE_HEIGHTS,
} from './helpers'
import { showSpaceAboveModal } from './SpaceAboveModal'

interface Props {
  script: ILoadedScript
  disabled: boolean
}

export const SpacingMenu = React.forwardRef<HTMLButtonElement, Props>(
  function SpacingMenu(props, ref) {
    const { script, disabled } = props
    const [opened, setOpened] = React.useState(false)

    if (!script.observableEditor) return null

    const { editorView } = script.observableEditor

    const blockName = getSelFormatBlockName(editorView.state)

    if (!blockName) return null

    const defaultLH = script.currentFormatValue(blockName, 'lineHeight')
    const defaultBTM = script.currentFormatValue(blockName, 'blockTopMargin')

    const { attrs } = editorView.state.selection.$from.parent

    const currLH = attrs.lineHeight
    const currBTM = attrs.blockTopMargin

    const setSpaceAbove = (spaceAbove: number) => {
      const value = spaceAbove === defaultBTM ? null : spaceAbove
      setSpacingAttr({ key: 'blockTopMargin', value, editorView })
      script.trackEvent('PARAGRAPH_SPACE_ABOVE_CHANGED')
    }

    return (
      <Menu opened={opened} onChange={setOpened}>
        <Menu.Target>
          <Toolbar.Button
            ref={ref}
            tooltip="Line spacing"
            disableTooltip={opened}
            active={currLH || currBTM}
            disabled={disabled}
            icon="fa-line-height"
            dropdown
          />
        </Menu.Target>
        <Menu.Dropdown>
          {VALID_LINE_HEIGHTS.map((h) => {
            const isDefault = h === defaultLH
            const value = isDefault ? null : h
            return (
              <FancyMenuItem
                key={value}
                onClick={() => {
                  setSpacingAttr({ key: 'lineHeight', value, editorView })
                  script.trackEvent('PARAGRAPH_SPACING_CHANGED')
                }}
                title={h + (isDefault ? ' (default)' : '')}
                selected={h === currLH}
              ></FancyMenuItem>
            )
          })}

          <Menu.Divider />
          <FancyMenuItem
            onClick={() =>
              showSpaceAboveModal({
                currentValue: currBTM ?? defaultBTM,
                defaultValue: defaultBTM,
                onConfirm: setSpaceAbove,
              })
            }
            selected={!!currBTM}
            title="Space above..."
          ></FancyMenuItem>
        </Menu.Dropdown>
      </Menu>
    )
  },
)
