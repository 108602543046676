import { ScriptDocType } from '@showrunner/codex'

import { FontCode, ScriptFormatConfiguration } from '@util/formats'

export type PaginationType = Exclude<
  ScriptFormatConfiguration['paginationType'],
  undefined
>

// TODO: this needs to get exported from codex so we don't need to redefine
const PAGINATION_TYPE_VALUES: PaginationType[] = [
  'inline',
  'none',
  'structural',
] as const
export function isPaginationType(value: unknown): value is PaginationType {
  return (
    typeof value === 'string' &&
    PAGINATION_TYPE_VALUES.some((item) => item === value)
  )
}

export type DocAttrs = {
  docType: ScriptDocType
  paginationType?: PaginationType | null
  fontCode?: FontCode
}
