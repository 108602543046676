import { Route, Switch } from 'wouter'

import * as Staff from '@components/Staff'
import { CenteredScrollingBody } from '@layouts'
import { ROUTE_PATTERNS } from '@util'

import { GuardedRoute } from './util/GuardedRoute'

export const StaffRouter = () => (
  <GuardedRoute path="/staff/:rest*" guard={(mst) => mst.user.staff}>
    <CenteredScrollingBody>
      <Switch>
        <Route
          path={ROUTE_PATTERNS.staffLanding}
          component={Staff.LandingPage}
        />
        <Route
          path={ROUTE_PATTERNS.staffWorkspaces}
          component={Staff.Workspaces}
        />
        <Route
          path={ROUTE_PATTERNS.staffWorkspaceDetail}
          component={Staff.OrgDetail}
        />
        <Route
          path={ROUTE_PATTERNS.staffCloneWorkspace}
          component={Staff.CloneWorkspace}
        />
        <Route path={ROUTE_PATTERNS.staffInvites} component={Staff.Invites} />
        <Route
          path={ROUTE_PATTERNS.staffTemplateWorkspaces}
          component={Staff.Templates}
        />
        <Route path={ROUTE_PATTERNS.staffUsers} component={Staff.Users} />
        <Route
          path={ROUTE_PATTERNS.staffUserDetail}
          component={Staff.UserDetail}
        />
        <Route
          path={ROUTE_PATTERNS.staffFormatDetail}
          component={Staff.FormatDetail}
        />
        <Route
          path={ROUTE_PATTERNS.staffFormats}
          component={Staff.FormatEditor}
        />
        <Route path={ROUTE_PATTERNS.staffFlags} component={Staff.BetaFlags} />
        <Route path={ROUTE_PATTERNS.staffRundowns} component={Staff.Rundowns} />
      </Switch>
    </CenteredScrollingBody>
  </GuardedRoute>
)
