import { Combobox, InputBase, Stack, Text, useCombobox } from '@mantine/core'

import { schemas } from '@showrunner/scrapi'

import { appendFontFallback, FontCode, FontCodeMap } from '@util/formats'

const fonts = Object.entries(FontCodeMap).map(([value, label]) => {
  return { value, label }
})

export const FontPicker = ({
  fontCode,
  setFontCode,
}: {
  fontCode: FontCode
  setFontCode: Callback<[FontCode]>
}) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })

  const font = FontCodeMap[fontCode]
  return (
    <Stack gap={2} mb={20} mt={20}>
      <Text fw="bold">Font family</Text>
      <Combobox
        store={combobox}
        onOptionSubmit={(val) => {
          const { data, success } =
            schemas.scriptFormats.FontCode.safeParse(val)
          if (success) setFontCode(data)
          combobox.closeDropdown()
        }}
      >
        <Combobox.Target>
          <InputBase
            component="button"
            styles={{
              input: { fontFamily: appendFontFallback(font) },
            }}
            type="button"
            pointer
            rightSection={<Combobox.Chevron />}
            rightSectionPointerEvents="none"
            onClick={() => combobox.toggleDropdown()}
          >
            {font}
          </InputBase>
        </Combobox.Target>
        <Combobox.Dropdown>
          <Combobox.Options>
            {fonts.map((f) => (
              <Combobox.Option
                value={f.value}
                key={f.value}
                ff={appendFontFallback(f.label)}
              >
                {f.label}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </Stack>
  )
}
