import { StandardBlockConfig } from '@util/formats'
import { PageLayout } from '@util/PageLayout'
import { chars, pixels } from '@util/Unit'

export type ConfiguredMarginValue = Pick<
  StandardBlockConfig,
  'marginLeft' | 'width'
>
export type SliderPositions = {
  leftPx: number
  rightPx: number
}

export type TickData = {
  label?: string
}

export type AllowedRange = {
  minLeftPx: number
  maxRightPx: number
  minWidthPx: number
}

export type RulerConfig = {
  displayedUnitSize: number
  ticksPerUnit: number
  unscaledWidthPx: number
  allowedRange: AllowedRange
}

// this is hard-coded to 10 but we might want to move it into
// PageLayout if, for example, we wanted 1mm tick width for cm and
// 1/8" tick width for inches
const STANDARD_TICKS_PER_UNIT = 10

export const rulerConfigFromLayout = (pageLayout: PageLayout): RulerConfig => {
  const pageWidth = pageLayout.data.width
  const { marginLeftMin, marginRightMin } = pageLayout.limitsInDisplayUnits
  const minLeftPx = pageLayout.displayValueToPx(marginLeftMin)
  const minWidthPx = pageLayout.displayValueToPx(pageLayout.data.minBlockWidth)
  const maxRightPx = pageLayout.displayValueToPx(pageWidth - marginRightMin)

  return {
    displayedUnitSize: pageWidth,
    ticksPerUnit: STANDARD_TICKS_PER_UNIT,
    unscaledWidthPx: pageLayout.displayValueToPx(pageWidth),
    allowedRange: {
      maxRightPx,
      minLeftPx,
      minWidthPx,
    },
  }
}

export const buildTicks = ({
  displayedUnitSize,
  ticksPerUnit,
}: Pick<RulerConfig, 'displayedUnitSize' | 'ticksPerUnit'>) => {
  const ticks: TickData[] = []
  const totalTicks = displayedUnitSize * ticksPerUnit
  for (let i = 0; i < totalTicks; i++) {
    const data: TickData = {}
    if (i % ticksPerUnit === 0) {
      data.label = String(i / ticksPerUnit)
    }
    ticks.push(data)
  }
  return ticks
}

// a margin configuration in a script format looks like
// { marginLeft: number; width: number }
// and the units are in ch (and don't account for the
// page's margin). This figures out what we actually need
// for the component
export const marginConfigToSliderPositions = ({
  blockMargins,
  minLeftPx,
}: {
  blockMargins: ConfiguredMarginValue
  minLeftPx: number
}): SliderPositions => {
  const leftPx = chars(blockMargins.marginLeft).toPx().value + minLeftPx
  const widthPx = chars(blockMargins.width).toPx().value
  const rightPx = leftPx + widthPx
  return {
    leftPx,
    rightPx,
  }
}

export const sliderPositionToMarginConfig = (
  { leftPx, rightPx }: SliderPositions,
  minLeftPx: number,
): ConfiguredMarginValue => {
  const widthPx = rightPx - leftPx

  return {
    marginLeft: pixels(leftPx - minLeftPx)
      .toCh()
      .toRounded(),
    width: pixels(widthPx).toCh().toRounded(),
  }
}
