import React from 'react'

import NiceModal from '@ebay/nice-modal-react'
import { Divider, Group, Menu, Stack, Tabs } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { z } from 'zod'

import { schemas } from '@showrunner/scrapi'

import { CustomIcon } from '@components/CustomIcon'
import { menuData } from '@components/EditorToolbar/ElementMenu/display-data'
import { FancyMenuItem } from '@components/FancyMenuItem'
import { ModalShell, useModalControls } from '@components/Modals'
import { ILoadedScript, useMst } from '@state'
import { BLACKISH_TEXT } from '@theme/colors'
import { getSelectionBlockType, setBlockOverrides } from '@util'
import {
  BlockOverrides,
  blockOverridesParser,
  FormatBlockName,
  isFormatBlockName,
  minifyOverrides,
} from '@util/formats'

import { FontPicker } from './FontPicker'
import { FormattingCheckboxes } from './FormattingCheckboxes'
import { BulkChangeHandler, changeEditorFontCode } from './helpers'
import { LayoutInputs } from './LayoutInputs'
import { TextColorPicker } from './TextColorPicker'

import styles from './CustomizeStyles.module.scss'

type MenuItem = FormatBlockName | 'script'

const overrideFormValuesSchema = z.object({
  fontCode: schemas.scriptFormats.FontCode,
  blockOverrides: blockOverridesParser,
})

type OverrideFormValues = z.infer<typeof overrideFormValuesSchema>

const gatherColors = ({
  block,
  script,
  overrides,
}: {
  block: MenuItem
  script: ILoadedScript
  overrides: BlockOverrides
}): [string, string, string] => {
  if (!isFormatBlockName(block)) return ['', '', '']

  const currentColor = script.currentFormatValue(block, 'color') ?? ''
  const pendingColor = overrides[block]?.color ?? currentColor
  const originalColor = script.originalFormatValue(block, 'color') ?? ''

  return [currentColor, pendingColor, originalColor]
}

const CustomizeStylesModal = NiceModal.create(
  ({ script }: { script: ILoadedScript }) => {
    const mst = useMst()
    const controls = useModalControls()
    const [activeTab, setActiveTab] = React.useState<string | null>('layout')

    const form = useForm<OverrideFormValues>({
      initialValues: {
        fontCode: script.fontCode,
        blockOverrides: script.blockOverrides ?? {},
      },
      validate: zodResolver(overrideFormValuesSchema),
    })

    const handleSubmit = form.onSubmit((values) => {
      if (form.isDirty('blockOverrides')) {
        const overrides = minifyOverrides(
          script.originalBlockFormats,
          values.blockOverrides,
        )
        setBlockOverrides(editorView, overrides)
        mst.trackEvent('SCRIPT_BLOCK_FORMATTING_CUSTOMIZED', { overrides })
      }

      if (form.isDirty('fontCode')) {
        changeEditorFontCode({ code: values.fontCode, script })
        mst.trackEvent('SCRIPT_FONT_CUSTOMIZED')
      }

      controls.onClose()
    })

    const menuItems = menuData[script.type]
    const { editorState, editorView } = script.pmEditor

    const pmSelectionBlockType = getSelectionBlockType(editorState)

    const findMenuItem = (id: string | undefined) =>
      menuItems.find((i) => i.id === id)

    const [selectedBlock, setSelectedBlock] = React.useState<MenuItem>(
      findMenuItem(pmSelectionBlockType)?.id ?? menuItems[0].id,
    )

    const setValues: BulkChangeHandler = (blockName, changes) => {
      form.setFieldValue('blockOverrides', {
        ...form.values.blockOverrides,
        [blockName]: {
          ...form.values.blockOverrides[blockName],
          ...changes,
        },
      })
    }

    const isBlock = isFormatBlockName(selectedBlock)
    const [currentColor, pendingColor, originalColor] = gatherColors({
      block: selectedBlock,
      script,
      overrides: form.values.blockOverrides,
    })

    return (
      <ModalShell
        size={600}
        title="Customize script and block types"
        opened={controls.opened}
        onClose={controls.onClose}
        onConfirm={handleSubmit}
        confirmLabel="Save"
        cancelLabel="Cancel"
        errorMessage={controls.errorMessage}
        loading={controls.loading}
        disabled={!form.isValid() || !form.isDirty()}
      >
        <Group align="start" gap={0}>
          <Menu classNames={{ item: styles.menuItem }}>
            <Stack gap={5}>
              <FancyMenuItem
                iconClass="fas fa-tv-retro"
                title="Entire script"
                selected={!isBlock}
                onClick={() => {
                  setSelectedBlock('script')
                  setActiveTab('styles')
                }}
              />
              <Menu.Label>Block types</Menu.Label>
              <Stack gap={5}>
                {menuItems.map((item) => {
                  const isSelected = selectedBlock === item.id
                  return (
                    <FancyMenuItem
                      key={item.id}
                      customIcon={
                        <CustomIcon
                          icon={item.icon}
                          c={isSelected ? 'white' : 'dark.4'}
                        />
                      }
                      title={item.title}
                      selected={isSelected}
                      onClick={() => setSelectedBlock(item.id)}
                    />
                  )
                })}
              </Stack>
            </Stack>
          </Menu>
          <Divider orientation="vertical" ml={10} mr={20} />
          <Tabs
            classNames={{
              tab: styles.elementCustom_tab,
            }}
            value={activeTab}
            onChange={setActiveTab}
          >
            <Tabs.List>
              <Tabs.Tab value="layout" disabled={!isBlock}>
                Layout
              </Tabs.Tab>
              <Tabs.Tab value="styles">Styles</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel
              value="layout"
              className={styles.elementCustom_tabPanel}
              pb={20}
              pt={20}
              px={20}
            >
              {isBlock && (
                <LayoutInputs
                  script={script}
                  unsavedOverrides={form.values.blockOverrides}
                  selectedBlock={selectedBlock}
                  onChange={setValues}
                />
              )}
            </Tabs.Panel>
            <Tabs.Panel
              value="styles"
              className={styles.elementCustom_tabPanel}
              pb={20}
              pt={20}
              px={20}
            >
              {isBlock ? (
                <Group align="flex-start" mt={20}>
                  <TextColorPicker
                    pendingColor={pendingColor}
                    currentColor={currentColor}
                    originalColor={originalColor}
                    onChange={(newColor: string) => {
                      const color =
                        newColor.toLowerCase() === BLACKISH_TEXT ? '' : newColor

                      setValues(selectedBlock, { color })
                    }}
                    originalColors={script.originalFormatColors}
                  />
                  <FormattingCheckboxes
                    script={script}
                    unsavedOverrides={form.values.blockOverrides}
                    selectedBlock={selectedBlock}
                    onChange={setValues}
                  />
                </Group>
              ) : (
                <FontPicker
                  fontCode={form.values.fontCode}
                  setFontCode={(code) => form.setFieldValue('fontCode', code)}
                />
              )}
            </Tabs.Panel>
          </Tabs>
        </Group>
        <Divider />
      </ModalShell>
    )
  },
)

export const showCustomizeStylesModal = (script: ILoadedScript) => {
  NiceModal.show(CustomizeStylesModal, { script })
}
