import { types } from 'mobx-state-tree'

import { OrgPermission, OrgPermissionMap } from '@showrunner/codex'

import { CollectionId, SplitEditorPref } from '@state/types'
import { getAvatarUrl } from '@util'
import {
  EditorPdfPreferences,
  InkPreferences,
  schemas,
  SortOrder,
  SortOrderPreferences,
  UserPreferences,
} from '@util/LocalPersistence'
import {
  DEFAULT_SCRIPT_PREFS,
  RundownPrintPrefs,
  ScriptPrintPreferences,
} from '@util/zodSchemas/printPreferences'

import { BaseModel } from './BaseModel'
import { IsoDate } from './IsoDate'

// This membership list is JUST name/values for the switcher... the real org info
// is on the root model.
export const Membership = BaseModel.named('Membership')
  .props({
    orgId: types.identifier,
    name: types.string,
    isAdmin: false,
    permissions: types.array(
      types.enumeration<OrgPermission>(Object.values(OrgPermissionMap)),
    ),
  })
  .actions((self) => ({
    setName(newName: string) {
      self.name = newName
    },
    setIsAdmin(value: boolean) {
      self.isAdmin = value
    },
  }))

export const User = BaseModel.named('User')
  .props({
    name: '',
    // the id of a user instance is set at create time and
    // NEVER changes
    id: types.optional(types.frozen(types.string), ''),
    stytchId: types.maybe(types.string),
    email: '',
    inkPreferences: types.maybe(types.frozen<InkPreferences>()),
    intercomHash: types.maybe(types.maybeNull(types.string)),
    avatar: types.maybe(types.maybeNull(types.string)),
    staff: false,
    // simple listing of orgs this user belongs to.
    // Used to populate the show switcher
    orgMemberships: types.array(Membership),
    selectedMembership: types.safeReference(Membership),
    prefs: types.frozen<UserPreferences>({}),
    sortOrderPrefs: types.frozen<SortOrderPreferences>({}),
    created: types.maybe(IsoDate),
    scriptPrintPrefs:
      types.frozen<ScriptPrintPreferences>(DEFAULT_SCRIPT_PREFS),
    rundownPrintPrefs: types.frozen<RundownPrintPrefs>(
      schemas.storedRundownPrintPrefs.parse(null),
    ),
  })
  .views((self) => ({
    isLoaded(): boolean {
      return !self.rootStore.loading
    },
    get avatarUrl(): string | undefined {
      return getAvatarUrl(self.avatar, self.environment.config)
    },
    get currentOrgId(): string | undefined {
      return self.selectedMembership?.orgId
    },
    get currentOrgName(): string | undefined {
      return self.selectedMembership?.name
    },
    get currentOrgPermissions(): Array<OrgPermission> {
      return self.selectedMembership ? self.selectedMembership.permissions : []
    },
    get currentOrgRole(): 'Owner' | 'Admin' | 'Contributor' {
      if (this.isCurrentOrgOwner) return 'Owner'
      if (this.isCurrentOrgAdmin) return 'Admin'
      return 'Contributor'
    },
    get isCurrentOrgOwner(): boolean {
      return self.rootStore.currentOrg?.owner.id === self.id
    },
    get isCurrentOrgAdmin(): boolean {
      return !!self.selectedMembership?.isAdmin
    },
    get isCurrentOrgContributor(): boolean {
      return !self.selectedMembership?.isAdmin
    },
    get canEditRundowns(): boolean {
      return this.currentOrgPermissions.includes(
        OrgPermissionMap.UPDATE_RUNDOWNS,
      )
    },
    get canPushToPrompter(): boolean {
      return this.currentOrgPermissions.includes(
        OrgPermissionMap.PUSH_TO_PROMPTER,
      )
    },
    get canEditLimited(): boolean {
      return this.currentOrgPermissions.includes(
        OrgPermissionMap.MODIFY_LIMITED_SCRIPT,
      )
    },
    get canChangeScriptStatus(): boolean {
      return this.currentOrgPermissions.includes(
        OrgPermissionMap.CHANGE_SCRIPT_STATUS,
      )
    },
    get canDestroyContent(): boolean {
      return this.currentOrgPermissions.includes(
        OrgPermissionMap.DESTROY_CONTENT,
      )
    },
    get editorPdfPreferences() {
      return self.environment.localPersistence.getEditorPdfPreferences(self.id)
    },
    get sortedOrgMemberships() {
      return [...self.orgMemberships].sort((a, b) =>
        a.name.localeCompare(b.name),
      )
    },
    get prefersColumnLayout() {
      return self.prefs.splitLayout?.layout === 'columns'
    },
    get prefersRowLayout() {
      const { splitLayout } = self.prefs
      return splitLayout ? splitLayout.layout === 'rows' : true
    },
    get prefersSplitLayout() {
      return self.prefs.splitLayout?.enabled ?? true
    },
    get currentFolderSortOrder(): SortOrder {
      return this.getFolderSortOrder(self.rootStore.view.selectedFolderId)
    },
    getFolderSortOrder(folderId: string): SortOrder {
      // in the past we allowed users to custom sort recently edited documents
      // now we need to ignore  their local pref if one is set
      if (!self.sortOrderPrefs[folderId] || folderId === 'recentlyEdited') {
        return 'recent'
      }
      return self.sortOrderPrefs[folderId]
    },
    belongsToOrg(orgId: unknown) {
      return !!self.orgMemberships.find((o) => o.orgId === orgId)
    },
  }))
  .actions((self) => ({
    setName(newName: string) {
      self.name = newName
    },
    setEmail(newEmail: string) {
      self.email = newEmail
    },
    setAvatar(newAvatar: string) {
      self.avatar = newAvatar
    },
    selectMembership(orgId: string) {
      this.updatePreferences({ currentOrgId: orgId })
      self.selectedMembership = self.orgMemberships.find(
        (om) => om.orgId === orgId,
      )
    },
    updateInkPreferences(value: Partial<InkPreferences>) {
      const newValue = { ...self.inkPreferences, ...value }
      self.inkPreferences = newValue
      self.environment.localPersistence.setInkPreferences(
        self.rootStore.user.id,
        newValue,
      )
    },
    updatePreferences(updates: Partial<UserPreferences>) {
      const mergedPrefs: UserPreferences = { ...self.prefs, ...updates }
      self.prefs = mergedPrefs
      self.environment.localPersistence.setUserPreferences({
        userId: self.id,
        preferences: mergedPrefs,
      })
    },
    updatePdfPreferences(updates: EditorPdfPreferences) {
      const mergedPrefs: EditorPdfPreferences = {
        ...self.editorPdfPreferences,
        ...updates,
      }
      self.environment.localPersistence.setEditorPdfPreferences({
        userId: self.id,
        preferences: mergedPrefs,
      })
    },
    updateScriptPrintPreferences(updates: Partial<ScriptPrintPreferences>) {
      const mergedPrefs: ScriptPrintPreferences = {
        ...self.scriptPrintPrefs,
        ...updates,
      }
      self.scriptPrintPrefs = mergedPrefs
      self.environment.localPersistence.setScriptPrintPreferences({
        userId: self.id,
        prefs: mergedPrefs,
      })
    },
    updateRundownPrintPrefs(updates: Partial<RundownPrintPrefs>) {
      const mergedPrefs: RundownPrintPrefs = {
        ...self.rundownPrintPrefs,
        ...updates,
      }
      self.rundownPrintPrefs = mergedPrefs
      self.environment.localPersistence.setRundownPrintPrefs(
        self.id,
        mergedPrefs,
      )
    },
    setFolderSortOrder(folderId: string, order: SortOrder) {
      const preferences = { ...self.sortOrderPrefs, [folderId]: order }
      self.sortOrderPrefs = preferences
      self.environment.localPersistence.setSortOrderPreferences({
        userId: self.id,
        preferences,
      })
    },
    setCollectionSortOrder(collectionId: CollectionId, order: SortOrder) {
      const preferences = { ...self.sortOrderPrefs, [collectionId]: order }
      self.sortOrderPrefs = preferences
      self.environment.localPersistence.setSortOrderPreferences({
        userId: self.id,
        preferences,
      })
    },
    setSuppressConfirmations(val: boolean) {
      this.updatePreferences({ suppressConfirmations: val })
    },
    setCurrentFolderSortOrder(order: SortOrder) {
      this.setFolderSortOrder(self.rootStore.view.selectedFolderId, order)
    },
    enterSplitLayout() {
      self.rootStore.analytics.trackEnterSplitView()
      this.updatePreferences({
        splitLayout: {
          ...self.prefs.splitLayout,
          layout: self.prefs.splitLayout?.layout ?? 'rows',
          enabled: true,
        },
      })
    },
    exitSplitLayout() {
      self.rootStore.analytics.trackExitSplitView()
      this.updatePreferences({
        splitLayout: {
          ...self.prefs.splitLayout,
          layout: self.prefs.splitLayout?.layout ?? 'rows',
          enabled: false,
        },
      })
    },
    updateLayoutPreference(layout: SplitEditorPref) {
      self.rootStore.analytics.trackChangeSplitView(layout)
      this.updatePreferences({
        splitLayout: {
          ...self.prefs.splitLayout,
          layout,
          enabled: true,
        },
      })
    },
    togglePagelessMode() {
      const newValue = !self.prefs.pageless
      this.setPagelessMode(newValue)
    },
    disablePagelessMode() {
      this.setPagelessMode(false)
    },
    enablePagelessMode() {
      this.setPagelessMode(true)
    },
    setPagelessMode(newValue: boolean) {
      this.updatePreferences({
        pageless: newValue,
      })
      self.trackEvent(
        newValue ? 'ENABLED_PAGELESS_MODE' : 'DISABLED_PAGELESS_MODE',
      )
    },
    // these are the preferences we refresh across browser
    // tabs/windows
    refreshSyncedPrefs() {
      const { localPersistence } = self.environment
      self.scriptPrintPrefs = localPersistence.getScriptPrintPreferences({
        userId: self.id,
      })
      self.rundownPrintPrefs = localPersistence.getRundownPrintPrefs(self.id)
    },
    refreshAllPrefs() {
      const { localPersistence } = self.environment
      self.prefs = localPersistence.getUserPreferences(self.id)
      self.inkPreferences = localPersistence.getInkPreferences(self.id)
      self.sortOrderPrefs = localPersistence.getSortOrderPreferences(self.id)
      this.refreshSyncedPrefs()
    },
    // After the mst tree is set up, use the userId and the
    // kv store to load up the user preferences from local storage (or memory for tests)
    afterAttach() {
      if (self.id) {
        this.refreshAllPrefs()
        self.analytics.setPersistentEventProperties({
          userId: self.id,
        })

        if (self.rootStore.view.isDebugEnabled('toolbar')) {
          this.updatePreferences({ showDebugToolbar: true })
        }
      }
    },
    dismissAnnouncement() {
      this.updatePreferences({ whatsNew: new Date().getTime() })
    },
    resetAnnouncement() {
      this.updatePreferences({ whatsNew: undefined })
      window.location.reload()
    },
  }))
  // async actions
  .actions((self) => ({
    async update({ name, email }: { name?: string; email?: string }) {
      const user = await self.apiClient.updateUser({
        userId: self.id,
        name,
        email,
      })

      if (name) self.setName(user.name)
      if (email) self.setEmail(user.email)

      return user
    },
    async uploadAvatar({ avatar }: { avatar: File }) {
      const response = await self.apiClient.uploadAvatar({
        userId: self.id,
        avatar,
      })
      self.setAvatar(response.avatar)
      return response
    },
  }))
