import { Table, Text } from '@mantine/core'

import { SmartFaIcon } from '@components/FaIcon'
import { NavAnchor } from '@components/NavAnchor'
import { useStaffFormats } from '@hooks'

export const FormatsList = () => {
  const { formatsQuery } = useStaffFormats()
  const { isPending, isError, data } = formatsQuery

  if (isPending) {
    return <div>loading formats</div>
  }
  if (isError) {
    return <div>error fetching formats</div>
  }

  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Name</Table.Th>
          <Table.Th>Id</Table.Th>
          <Table.Th>Icon</Table.Th>
          <Table.Th>Replaced by</Table.Th>
          <Table.Th>Workspace assigments</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {data
          .sort((a, b) => a.id.localeCompare(b.id))
          .map((format) => (
            <Table.Tr key={format.id}>
              <Table.Td>{format.name}</Table.Td>
              <Table.Td>
                <NavAnchor href={`/staff/formats/${format.id}`}>
                  <Text fz="14px">{format.id}</Text>
                </NavAnchor>
              </Table.Td>
              <Table.Td>
                <SmartFaIcon icon={format.icon} fallback={<div>icon</div>} />
              </Table.Td>
              <Table.Td>{format.replacedById}</Table.Td>
              <Table.Td>{format.workspaceCount}</Table.Td>
            </Table.Tr>
          ))}
      </Table.Tbody>
    </Table>
  )
}
