/*
  The Config data plugin does the work of merging info from the immutable
  script format configuration (created at the birth of the script) with overrides
  from the prose doc.  Since the plugin is used for snapshots and scripts,
  the factory uses a simplified payload that works for a live editor PluginFactory
  signature or a script or snapshot api payload
*/

import { deepMerge } from '@mantine/core'
import deepEqual from 'fast-deep-equal'
import { Node as PmNode } from 'prosemirror-model'
import { EditorState, Plugin, PluginKey } from 'prosemirror-state'

import {
  getBlockOverrides,
  getPageConfigOverrides,
  isPaginationType,
  mergePageConfig,
  PaginationType,
  resolveFontCode,
} from '@util'
import {
  BlockFormats,
  BlockOverrides,
  FontCode,
  PageConfig,
  ScriptFormatConfiguration,
} from '@util/formats'

type PluginParams = {
  script: {
    scriptFormat: {
      definition: ScriptFormatConfiguration
    }
  }
}

type ConfigData = {
  fontCode: FontCode
  paginationType: PaginationType
  scriptType: 'ink' | undefined
  blockOverrides: BlockOverrides
  currentBlockFormats: BlockFormats
  pageConfigOverrides: Partial<PageConfig>
  currentPageConfig: PageConfig
}

export const configDataPluginKey = new PluginKey<ConfigData>('configData')

const resolvePaginationType = (
  doc: PmNode,
  baseFormat: ScriptFormatConfiguration,
): PaginationType => {
  const proseDocValue = doc.attrs.paginationType
  if (isPaginationType(proseDocValue)) {
    return proseDocValue
  }
  const { paginationType, scriptType } = baseFormat
  if (paginationType) {
    return paginationType
  }

  return scriptType === 'ink' ? 'none' : 'structural'
}

export const configDataPlugin = ({
  script,
}: PluginParams): Plugin<ConfigData> => {
  const config = script.scriptFormat.definition
  const { blocks: defaultBlockFormat, scriptType } = config

  return new Plugin({
    key: configDataPluginKey,
    state: {
      init(_, editorState) {
        const paginationType = resolvePaginationType(editorState.doc, config)
        const blockOverrides = getBlockOverrides(editorState.doc)
        const currentBlockFormats = deepMerge(
          defaultBlockFormat,
          blockOverrides,
        )
        const fontCode = resolveFontCode(editorState.doc, config)

        return {
          paginationType,
          blockOverrides,
          currentBlockFormats,
          scriptType,
          fontCode,
          pageConfigOverrides: getPageConfigOverrides(editorState.doc),
          currentPageConfig: mergePageConfig(editorState.doc, config),
        }
      },
      apply(tr, pluginState, oldState, newState) {
        const meta = tr.getMeta(configDataPluginKey)

        if (!meta && oldState.doc.eq(newState.doc)) {
          return pluginState
        }

        const blockOverrides = getBlockOverrides(newState.doc)
        const pageConfigOverrides = getPageConfigOverrides(newState.doc)
        const fontCode = resolveFontCode(newState.doc, config)

        if (
          !meta &&
          fontCode === pluginState.fontCode &&
          deepEqual(blockOverrides, pluginState.blockOverrides) &&
          deepEqual(pageConfigOverrides, pluginState.pageConfigOverrides)
        ) {
          return pluginState
        }

        return {
          paginationType: resolvePaginationType(newState.doc, config),
          scriptType,
          blockOverrides,
          currentBlockFormats: deepMerge(defaultBlockFormat, blockOverrides),
          fontCode,
          pageConfigOverrides,
          currentPageConfig: mergePageConfig(newState.doc, config),
        }
      },
    },
  })
}

// Helper to extract config data. This assumes that the editor state is
// ALWAYS created with the configData plugin
export const getConfigData = (editorState: EditorState): ConfigData => {
  const result = configDataPluginKey.getState(editorState)
  if (!result) {
    throw new Error('Invalid plugin configuration, configData not present')
  }
  return result
}
