import { Node as PmNode } from 'prosemirror-model'

import { BlockFormats } from '@util/formats'
import { PageLayout } from '@util/PageLayout'

import { extractBlockInfo } from './helpers'
import { PaginationBreakdown as PaginationBreakdownImpl } from './PaginationBreakdown'

export const createPaginationBreakdown = ({
  doc,
  blockFormats,
  pageLayout,
}: {
  doc: PmNode
  blockFormats: BlockFormats
  pageLayout: PageLayout
}) => {
  const blocks = extractBlockInfo({ doc, blockFormats })
  return new PaginationBreakdownImpl({
    blocks,
    maxLines: pageLayout.maxLinesPerPage,
  })
}

export type PaginationBreakdown = ReturnType<typeof createPaginationBreakdown>
