import React from 'react'

import { NodeTypeKey, NodeTypeMap } from '@showrunner/codex'

import { DocInfoState } from '@choo-app/lib/editor/plugins/doc-info'
import { showAsyncConfirmModal } from '@components/Modals'
import { ILoadedScript, NumberableNodeTypeKey } from '@state/types'
import { capitalize } from '@util'
import { chars, inches } from '@util/Unit'

import { PaidMenuItem } from './PaidMenuItem'

// TODO: check individual block overrides of the selected type too?
const mightOverlap = (
  key: NumberableNodeTypeKey,
  script: ILoadedScript,
): boolean => {
  const pageMarginPx = script.pageLayout.displayValueToPx(
    script.pageLayout.limitsInDisplayUnits.marginLeftMin,
  )

  const blockMarginPx = chars(
    script.currentFormatValue(key, 'marginLeft'),
  ).toPx().value

  // ~1" in the gutter is needed to avoid element numbers overlapping content
  if (pageMarginPx + blockMarginPx < inches(1).toPx().value) {
    return true
  }

  return false
}

const getNumberingState = (
  docNumbering: DocInfoState['elementNumbers'],
  key: NodeTypeKey,
): {
  nodeExists?: boolean
  isNumbered?: boolean
} => {
  const docInfoForKey = docNumbering[key]
  const docInfoForAltKey =
    key === NodeTypeMap.DIALOGUE
      ? docNumbering[NodeTypeMap.DUAL_DIALOGUE]
      : undefined
  return {
    nodeExists: docInfoForKey?.nodeExists || docInfoForAltKey?.nodeExists,
    isNumbered: docInfoForKey?.isNumbered || docInfoForAltKey?.isNumbered,
  }
}

export const NumberingItems = ({
  isUnpaidOrg,
  script,
  docNumbering,
}: {
  docNumbering: DocInfoState['elementNumbers']
  isUnpaidOrg: boolean
  script: ILoadedScript
}) => {
  const numberingItems: React.ReactNode[] = []

  script.numberableBlockTypes.forEach((nodeType) => {
    const { isNumbered, nodeExists } = getNumberingState(docNumbering, nodeType)
    if (nodeExists) {
      const displayType =
        nodeType === NodeTypeMap.SCENE_HEADING ? 'scene' : nodeType
      const action = isNumbered ? 'remove' : 'add'
      const title = `${capitalize(action)} ${displayType} numbers`

      const toggleNumbering = () => {
        if (isNumbered) {
          showAsyncConfirmModal({
            title: 'Element Numbering',
            children: `Are you sure you want to remove all ${displayType} numbers?`,
            errorMessage: 'Failed to remove element numbers',
            onConfirm: () =>
              Promise.resolve(script.toggleElementNumbers(nodeType)),
            dangerous: true,
          })
        } else {
          if (mightOverlap(nodeType, script)) {
            script.trackEvent('SCRIPT_ELEMENT_NUMBERING_OVERLAP', { nodeType })
          }
          script.toggleElementNumbers(nodeType)
          script.trackEvent('SCRIPT_ELEMENTS_NUMBERED', { nodeType })
        }
      }

      numberingItems.push(
        <PaidMenuItem
          key={nodeType}
          title={title}
          onClick={toggleNumbering}
          disabled={!nodeExists || !script.isEditable}
          isUnpaidOrg={isUnpaidOrg}
        ></PaidMenuItem>,
      )
    }
  })

  return <>{numberingItems}</>
}
