import { Image, Menu, Stack, Text } from '@mantine/core'

import whatsNewShowcase from '@assets/images/whats-new/scripto-formatting.gif'
import { OutsideAnchor } from '@components/OutsideAnchor'

import styles from './WhatsNewBlurb.module.scss'

export const WhatsNewBlurb = () => (
  <>
    <Image src={whatsNewShowcase} style={{ width: '450px' }} />
    <Menu.Label className={styles.blurb_textOnly}>
      <Stack gap={2}>
        <Text fw="bold">New formatting features 🎉</Text>
        <Text>
          You can now customize margins, line spacing, colors, and more.
        </Text>
        <Text fs="xs">
          <OutsideAnchor href="https://help.scripto.live/en/articles/10355706-formatting-features">
            Learn more
          </OutsideAnchor>
        </Text>
      </Stack>
    </Menu.Label>
    <Menu.Divider />
  </>
)
