import { schemas } from '@showrunner/scrapi'

import { PageConfig, ScriptFormatConfiguration } from '@util/formats'
import { createProsemirrorDoc, mergePageConfig } from '@util/prosemirrorHelpers'
import { ScriptJson } from '@util/ScriptoApiClient/types'
import { Unit } from '@util/Unit'

// this is what we store in script formats, both
// values are in ch and the left margin starts 1.5 in from the left
// of the page
export type HorizontalMarginConfig = {
  marginLeft: number
  width: number
}
// Our one and only font metric is for a 16px line height. If we ever want
// to change font sizes, this is what we'd need to parameterize to get how
// many lines fit per page
const LINE_HEIGHT_PX = 16

export class PageLayout {
  readonly data: PageConfig

  constructor(data: PageConfig) {
    this.data = data
  }

  private getUnit(value: number) {
    return new Unit(value, this.data.unit)
  }

  toCssDisplayUnits(value: number): string {
    return this.getUnit(value).toRoundedCssValue()
  }

  get limitsInDisplayUnits() {
    const marginLeftMin = this.data.marginLeft
    const marginRightMin = this.data.marginRight
    const maxLineWidth = this.data.width - (marginLeftMin + marginRightMin)
    const marginLeftMax = marginLeftMin + maxLineWidth - this.data.minBlockWidth
    const marginRightMax =
      marginRightMin + maxLineWidth - this.data.minBlockWidth
    return {
      marginLeftMin,
      marginRightMin,
      marginLeftMax,
      marginRightMax,
      maxLineWidth,
    }
  }

  get pageWidthPx() {
    return this.displayValueToPx(this.data.width)
  }

  get pageHeightPx() {
    return this.displayValueToPx(this.data.height)
  }

  get pageMarginsPx() {
    const { marginBottom, marginLeft, marginRight, marginTop } = this.data
    return {
      left: this.displayValueToPx(marginLeft),
      right: this.displayValueToPx(marginRight),
      top: this.displayValueToPx(marginTop),
      bottom: this.displayValueToPx(marginBottom),
    }
  }

  chToDisplayUnits(chVal: number): number {
    const chUnit = new Unit(chVal, 'ch')
    switch (this.data.unit) {
      case 'in':
        return chUnit.toInches().value
      case 'cm': {
        return chUnit.toCm().value
      }
    }
  }

  // pass in the marginLeft of a block format and get back a display
  // value that includes the page config's marginLeft
  leftMarginChToDisplay = (ch: number) => {
    return this.chToDisplayUnits(ch) + this.data.marginLeft
  }

  // pass in the display value for marginLeft from a LayoutInput and
  // get back the appropriate value for a block format (it will remove
  // the page config's marginLeft before converting)
  leftMarginDisplayToCh = (value: number) => {
    return this.getUnit(value - this.data.marginLeft).toCh().value
  }

  // convert a block format's marginLeft/width into what we show in
  // the LayoutInputs component
  marginConfigToDisplayUnits(config: HorizontalMarginConfig) {
    const left = this.leftMarginChToDisplay(config.marginLeft)
    const rightPos = left + this.chToDisplayUnits(config.width)
    const right = this.data.width - rightPos
    return {
      left,
      right,
    }
  }

  // UI says: Left = 1.5" right = 1" and we want { marginLeft: 0, width: 60 }
  marginDisplayToConfig({
    left,
    right,
  }: {
    left: number
    right: number
  }): HorizontalMarginConfig {
    const marginLeft = this.leftMarginDisplayToCh(left)
    const displayWidth = this.data.width - (left + right)
    const width = this.getUnit(displayWidth).toCh().value
    return {
      marginLeft,
      width,
    }
  }

  isTooNarrow(widthCh: number) {
    return this.chToDisplayUnits(widthCh) < this.data.minBlockWidth
  }

  get orientation(): 'portrait' | 'landscape' {
    const { height, width } = this.data
    return height > width ? 'portrait' : 'landscape'
  }

  getCssVars(): React.CSSProperties {
    const contentWidth = this.limitsInDisplayUnits.maxLineWidth
    return {
      '--page-width': this.toCssDisplayUnits(this.data.width),
      '--editor-content-width': this.toCssDisplayUnits(contentWidth),
      '--page-left-padding': this.toCssDisplayUnits(this.data.marginLeft),
      '--page-right-padding': this.toCssDisplayUnits(this.data.marginRight),
      '--page-top-padding': this.toCssDisplayUnits(this.data.marginTop),
      '--page-bottom-padding': this.toCssDisplayUnits(this.data.marginBottom),
    }
  }

  displayValueToPx(value: number) {
    return new Unit(value, this.data.unit).toPx().value
  }

  get maxLinesPerPage() {
    const contentHeight =
      this.data.height - (this.data.marginTop + this.data.marginBottom)
    const heightPx = this.displayValueToPx(contentHeight)
    return Math.floor(heightPx / LINE_HEIGHT_PX)
  }
}

const DEFAULT: PageConfig = {
  unit: 'in',
  height: 11,
  width: 8.5,
  minBlockWidth: 1,
  marginLeft: 1.5,
  marginRight: 1,
  marginTop: 1,
  marginBottom: 1,
}

const PROPOSED: PageConfig = {
  unit: 'in',
  height: 11,
  width: 8.5,
  minBlockWidth: 1,
  marginLeft: 0.5,
  marginRight: 0.5,
  marginTop: 1,
  marginBottom: 1,
}

const A4: PageConfig = {
  unit: 'cm',
  height: 29.7,
  width: 21,
  minBlockWidth: 2.5,
  marginLeft: 1,
  marginRight: 1,
  marginTop: 2.5,
  marginBottom: 2.5,
}

const WEIRDO: PageConfig = {
  unit: 'in',
  height: 6,
  width: 10,
  minBlockWidth: 2,
  marginLeft: 1,
  marginRight: 1,
  marginTop: 1,
  marginBottom: 1,
}

export const getPageLayout = (data?: PageConfig): PageLayout =>
  new PageLayout(data ?? schemas.scriptFormats.DEFAULT_PAGE_CONFIG)

export const getPageLayoutFromScript = (
  doc: ScriptJson,
  definition: ScriptFormatConfiguration,
): PageLayout =>
  getPageLayout(mergePageConfig(createProsemirrorDoc(doc), definition))

// These presets are used for tests and for the DevToolbar
export const LAYOUT_PRESETS = {
  DEFAULT,
  PROPOSED,
  A4,
  WEIRDO,
}
export type LayoutPreset = keyof typeof LAYOUT_PRESETS

// We don't support custom page configs yet, so this is temporary/hard-coded
// until we enable extracting from the ScriptFormat + proseDoc
export const LEGACY_PAGE_LAYOUT = new PageLayout(LAYOUT_PRESETS.DEFAULT)
