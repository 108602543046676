import { observer } from 'mobx-react-lite'

import { PLUGIN_KEY as REVISION_PLUGIN_KEY } from '@choo-app/lib/editor/plugins/revision-asterisks/helpers'
import { useMst } from '@hooks'
import { createComparisonEditorState } from '@util'
import { getPageLayoutFromScript } from '@util/PageLayout'
import { ScriptSnapshotPayload } from '@util/ScriptoApiClient/types'

import { useSnapshotData } from '../useSnapshotLandData'
import { SnapshotViewerBase } from '../viewer'

import { BodyPlaceholder } from './BodyPlaceholder'
import { IdenticalSnapshotsToast } from './IdenticalSnapshotsToast'

const PopulatedAsteriskView = ({
  newer,
  older,
}: {
  newer: Pick<ScriptSnapshotPayload, 'doc' | 'scriptFormat'>
  older: Pick<ScriptSnapshotPayload, 'doc'>
}) => {
  const mst = useMst()
  const editorState = createComparisonEditorState({
    script: newer,
    snapshot: older,
  })

  const revisionPluginState = REVISION_PLUGIN_KEY.getState(editorState)

  // we intentionally ignore the current user pref. asterisks are only for pages
  const pageless = false

  const pageLayout = getPageLayoutFromScript(
    newer.doc,
    newer.scriptFormat.definition,
  )

  return (
    <SnapshotViewerBase
      pageless={pageless}
      editorZoom={mst.user.prefs.editorZoom}
      editorState={editorState}
      pageLayout={pageLayout}
    >
      {revisionPluginState?.identical && <IdenticalSnapshotsToast mb={25} />}
    </SnapshotViewerBase>
  )
}

// Once user has supplied the two IDs
const RequestedAsteriskView = observer(function RequestedAsteriskView({
  scriptId,
  snap1Id,
  snap2Id,
}: {
  scriptId: string
  snap1Id: string
  snap2Id: string
}) {
  const snap1query = useSnapshotData({ scriptId, snapshotId: snap1Id })
  const snap2query = useSnapshotData({ scriptId, snapshotId: snap2Id })
  if (snap1query.isPending || snap2query.isPending) {
    return <BodyPlaceholder variant="loading" />
  }
  if (snap1query.isError || snap2query.isError) {
    return <BodyPlaceholder variant="error" />
  }

  return (
    <PopulatedAsteriskView newer={snap1query.data} older={snap2query.data} />
  )
})

export const AsteriskView = observer(function AsteriskView({
  scriptId,
}: {
  scriptId: string
}) {
  const { snap1, snap2 } = useMst().view.snapshotLand

  if (snap1 && snap2) {
    return (
      <RequestedAsteriskView
        scriptId={scriptId}
        snap1Id={snap1}
        snap2Id={snap2}
      />
    )
  }

  const hasOne = snap1 || snap2

  return <BodyPlaceholder variant={hasOne ? 'pickAnother' : 'pickTwo'} />
})
