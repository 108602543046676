import React from 'react'

import { ActionIcon, Popover, Stack, Tabs } from '@mantine/core'
import { EditorView } from 'prosemirror-view'

import { MarkTypeKey, MarkTypeMap } from '@showrunner/codex'

import { FaIcon } from '@components/FaIcon'
import { Toolbar } from '@components/Toolbar'

import { ColorPalette } from './ColorPalette'
import { updateMark } from './pmHelpers'

import styles from './ColorPickerPopover.module.scss'

const { COLOR, BACKGROUND_COLOR } = MarkTypeMap

interface Props {
  color: string
  bgColor: string
  editorView: EditorView
  active: boolean
  disabled: boolean
}
export const ColorPickerPopover = React.forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    const { color, bgColor, editorView, active, disabled } = props

    const [opened, setOpened] = React.useState(false)

    const onChange = (color: string, mark: MarkTypeKey) => {
      updateMark({ editorView, color, mark })
    }

    return (
      <Popover
        width={470}
        position="bottom"
        opened={opened}
        onChange={setOpened}
      >
        <Popover.Target>
          <Toolbar.Button
            ref={ref}
            onClick={() => setOpened((o) => !o)}
            icon="fa-palette"
            tooltip="Text and highlight color"
            disabled={disabled}
            active={active}
          />
        </Popover.Target>
        <Popover.Dropdown>
          <Stack gap={10} pb={10}>
            <Tabs
              classNames={{
                tabLabel: styles.colorPicker_tabLabel,
                list: styles.colorPicker_tabList,
              }}
              defaultValue="text"
            >
              <Tabs.List>
                <Tabs.Tab value="text">
                  <FaIcon className={styles.colorPicker_tabIcon} icon="fa-a" />
                  Text
                </Tabs.Tab>
                <Tabs.Tab value="highlight">
                  <FaIcon
                    className={styles.colorPicker_tabIcon___highlight}
                    icon="fa-a"
                    c="white"
                  />
                  Highlight
                </Tabs.Tab>
              </Tabs.List>
              <Tabs.Panel value="text">
                <ColorPalette
                  color={color}
                  onChange={(v) => onChange(v, COLOR)}
                />
              </Tabs.Panel>
              <Tabs.Panel value="highlight">
                <ColorPalette
                  color={bgColor}
                  onChange={(v) => onChange(v, BACKGROUND_COLOR)}
                  inputLabel="Background color"
                />
              </Tabs.Panel>
            </Tabs>
          </Stack>
          <ActionIcon
            className={styles.colorPicker_closeBtn}
            onClick={() => {
              editorView.focus()
              setOpened(false)
            }}
          >
            <FaIcon c="dark" icon="fa-xmark" />
          </ActionIcon>
        </Popover.Dropdown>
      </Popover>
    )
  },
)

ColorPickerPopover.displayName = 'ColorPickerPopover'
