import React from 'react'

import { Box, Group, Modal, Text } from '@mantine/core'
import { useElementSize, useInterval } from '@mantine/hooks'
import { RouteComponentProps } from 'wouter'

import { FaIcon } from '@components/FaIcon'
import { useMst, useNavigation } from '@hooks'
import { useUnloadRouteEffect } from '@routes'

import { HistoryAndBody } from './HistoryAndBody'
import { SnapshotLandToolbar } from './toolbar'
import { useScriptData } from './useSnapshotLandData'

// how often to poll for new snapshots when the query is mounted and
// we're in the foreground
const CHECK_FOR_LATEST_INTERVAL_MS = 60 * 1000

const Title = ({ name }: { name?: string }) => {
  const text = name ? (
    <Text span>
      Snapshot history for&nbsp;
      <Text span fw="bold">
        {name}
      </Text>
    </Text>
  ) : (
    <Text span>Snapshot history</Text>
  )

  return (
    <Group gap={6}>
      <FaIcon icon="fa-clock-rotate-left" />
      {text}
    </Group>
  )
}

export const SnapshotLand = ({
  params,
}: RouteComponentProps<{ scriptId: string }>) => {
  const { view } = useMst()
  const { navigate } = useNavigation()
  const scriptQuery = useScriptData(params.scriptId)
  const { ref: sizeRef, width } = useElementSize()

  React.useEffect(() => {
    view.snapshotLand.initializeForScript(params.scriptId)
  }, [params.scriptId, view.snapshotLand])

  const interval = useInterval(() => {
    view.snapshotLand.checkForNewer(params.scriptId)
  }, CHECK_FOR_LATEST_INTERVAL_MS)

  React.useEffect(() => {
    interval.start()
    return interval.stop
  }, [interval])

  // when we leave snapshotLand, we remove all the snapshot
  // state from mst
  useUnloadRouteEffect(() => {
    view.snapshotLand.tearDownSnapshotLand()
  })

  const exitSnapshotLand = () => {
    navigate(view.explorerState.lastScriptPath ?? `/scripts/${params.scriptId}`)
  }

  return (
    <Modal
      opened
      fullScreen
      withinPortal={false}
      xOffset={20}
      yOffset={20}
      title={<Title name={scriptQuery.data?.script.name} />}
      closeButtonProps={{
        icon: <FaIcon c="dark.9" icon="fa-xmark" size="18" />,
      }}
      onClose={exitSnapshotLand}
      overlayProps={{ backgroundOpacity: 1 }}
    >
      <Box h="100%" ref={sizeRef}>
        <SnapshotLandToolbar scriptId={params.scriptId} />
        <HistoryAndBody scriptId={params.scriptId} width={width} />
      </Box>
    </Modal>
  )
}
