import { Code, Group, JsonInput, Stack, Text, Title } from '@mantine/core'

import { SmartFaIcon } from '@components/FaIcon'
import { NavAnchor } from '@components/NavAnchor'
import { useStaffFormat } from '@hooks'

import styles from './FormatDetail.module.scss'

export const FormatDetail = ({ params }: { params: { formatId: string } }) => {
  const { formatQuery } = useStaffFormat(params.formatId)
  const { isPending, isError, data } = formatQuery

  if (isPending) return <div>loading format</div>
  if (isError) return <div>error fetching format</div>

  return (
    <Stack>
      <Title order={2}>
        <NavAnchor href="/staff">Staff Zone</NavAnchor>&nbsp;-&nbsp;
        <NavAnchor href="/staff/formats">Formats</NavAnchor>
      </Title>
      <Text>
        Name: <Code>{data.name}</Code>
      </Text>
      <Text>
        Id: <Code>{data.id}</Code>
      </Text>
      <Group>
        <Text>Icon:</Text>
        <SmartFaIcon icon={data.icon} fallback={<div>icon</div>} />
      </Group>
      <Text>
        Replaced by: <Code>{data.replacedById}</Code>
      </Text>
      <Text>
        Notes: <Code>{data.notes}</Code>
      </Text>
      <Text>
        Workspace assignments: <Code>{data.workspaceCount}</Code>
      </Text>
      <Title order={3}>Definition</Title>
      <JsonInput
        classNames={{ input: styles.input }}
        w={500}
        autosize
        disabled
        value={JSON.stringify(data.definition, null, 2)}
      />
    </Stack>
  )
}
