import { Select as SelectComponent } from '@mantine/core'

import styles from './select.module.scss'

export const Select = SelectComponent.extend({
  defaultProps: {
    allowDeselect: false,
    withCheckIcon: false,
    // stopgap: https://github.com/mantinedev/mantine/issues/7395
    rightSectionPointerEvents: 'none',
  },
  classNames: {
    option: styles.option,
  },
})
