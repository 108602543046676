import React from 'react'

import { observer } from 'mobx-react-lite'

import { DocumentOutlineExplorer } from '@components/DocumentOutline'
import { FolderExplorer } from '@components/FolderExplorer'
import { SearchExplorer, SearchProvider } from '@components/SearchExplorer'
import { useMst } from '@state'
import { ExplorerViewType } from '@state/types'

import { ExplorerSidestrip } from './ExplorerSidestrip'

import styles from './Explorer.module.scss'

export const Explorer = observer(function Explorer() {
  const searchInputRef = React.useRef<HTMLInputElement>(null)
  const { currentOrg, view } = useMst()
  const {
    isExplorerClosed,
    selectedView,
    selectView,
    dragging,
    setExplorerClosed,
    enabledViews,
  } = view.explorerState

  const handleSelect = (view: ExplorerViewType) => {
    if (isExplorerClosed) {
      selectView(view)
      setExplorerClosed(false)
    } else if (view === selectedView) {
      setExplorerClosed(true)
    } else if (enabledViews.includes(view)) {
      selectView(view)
      // because the search pane stays mounted, we need special logic to
      // re-autofocus the input each time it is foregrounded.
      if (view === 'search') {
        // TODO: advanced search: this setTimeout is gross
        setTimeout(() => searchInputRef.current?.focus(), 1)
      }
    }
  }

  const currentViewButton =
    isExplorerClosed && !dragging ? undefined : selectedView

  return (
    <div className={styles.explorer}>
      <ExplorerSidestrip
        enabledViews={enabledViews}
        currentView={currentViewButton}
        onSelectView={handleSelect}
      />
      {selectedView === 'browser' && <FolderExplorer />}
      {currentOrg && (
        <SearchProvider>
          <SearchExplorer org={currentOrg} inputRef={searchInputRef} />
        </SearchProvider>
      )}
      {selectedView === 'outline' && <DocumentOutlineExplorer />}
    </div>
  )
})
