import React from 'react'

import { Fragment, Node as PmNode } from 'prosemirror-model'

import { useMst } from '@state'
import {
  createProsemirrorDoc,
  extractSleneFragment,
  listSlenes,
  resolveFontCode,
} from '@util'
import { FontCode } from '@util/formats'

import { BlankSide, cardTitle, subtitle } from './helpers'
import { useMaybeSnapshot } from './useSnapshotLandData'

export type SleneItem = { label: string; id: string }

export type PopulatedSideBySideData = {
  leftTitle: string
  leftSubtitle: string
  leftEmphasis: boolean
  leftSlenes: Array<SleneItem>
  leftActiveSlene?: SleneItem
  rightTitle: string
  rightSubtitle: string
  rightEmphasis: boolean
  leftFragment: Fragment
  rightFragment: Fragment
  rightSlenes: Array<SleneItem>
  blankSide?: BlankSide
  rightActiveSlene?: SleneItem
  fontCode: FontCode
}
type SideBySideData =
  | 'no-data' // user hasn't picked anything
  | 'error' // we have an error and can't show anything
  | 'loading' // we don't have either side yet but have some data
  | PopulatedSideBySideData

export function isPopulatedData(
  data: SideBySideData,
): data is PopulatedSideBySideData {
  return typeof data !== 'string'
}

const getFragment = (
  doc: PmNode | null,
  sleneId: string | undefined,
): Fragment | null => {
  if (doc) {
    if (sleneId) {
      return extractSleneFragment(doc, sleneId)
    }
    return doc.content
  }
  return null
}

export const useSideBySideDiffData = (scriptId: string): SideBySideData => {
  const { snapAndBlock1: rightParams, snapAndBlock2: leftParams } =
    useMst().view.snapshotLand

  const leftQuery = useMaybeSnapshot({
    scriptId,
    snapshotId: leftParams?.snapshotId,
  })

  const rightQuery = useMaybeSnapshot({
    scriptId,
    snapshotId: rightParams?.snapshotId,
  })

  const leftDoc: PmNode | null = React.useMemo(() => {
    return leftQuery.data ? createProsemirrorDoc(leftQuery.data.doc) : null
  }, [leftQuery.data])
  const rightDoc: PmNode | null = React.useMemo(() => {
    return rightQuery.data ? createProsemirrorDoc(rightQuery.data.doc) : null
  }, [rightQuery.data])

  const leftFragment: Fragment | null = getFragment(
    leftDoc,
    leftParams?.blockId,
  )

  const rightFragment: Fragment | null = getFragment(
    rightDoc,
    rightParams?.blockId,
  )

  const leftSlenes: SleneItem[] = leftDoc ? listSlenes(leftDoc) : []
  const rightSlenes: SleneItem[] = rightDoc ? listSlenes(rightDoc) : []

  const leftActiveSlene = leftSlenes.find((sl) => sl.id === leftParams?.blockId)
  const rightActiveSlene = rightSlenes.find(
    (sl) => sl.id === rightParams?.blockId,
  )

  // if either side errors, return 'error'
  if (leftQuery.isError || rightQuery.isError) {
    return 'error'
  }

  // return loading if both sides are loading
  if (leftQuery.isPending && rightQuery.isPending) {
    return 'loading'
  }

  const leftTitle = cardTitle(leftQuery)
  const leftSubtitle = leftQuery.data ? subtitle(leftQuery.data, true) : ''
  const leftEmphasis = !leftQuery.data?.autoSave
  const rightTitle = cardTitle(rightQuery)
  const rightSubtitle = rightQuery.data ? subtitle(rightQuery.data, true) : ''
  const rightEmphasis = !rightQuery.data?.autoSave

  // editorial decision. use the most recently defined fontCode uniformly
  const fontCode = rightQuery.data
    ? resolveFontCode(
        rightQuery.data.doc,
        rightQuery.data?.scriptFormat.definition,
      )
    : 'courier-prime'

  const baseData: Omit<
    PopulatedSideBySideData,
    'leftFragment' | 'rightFragment' | 'blankSide'
  > = {
    leftTitle,
    leftSubtitle,
    leftEmphasis,
    leftSlenes,
    leftActiveSlene,
    rightTitle,
    rightSubtitle,
    rightEmphasis,
    rightSlenes,
    rightActiveSlene,
    fontCode,
  }

  // both sides ready and distinct
  if (leftFragment && rightFragment) {
    return {
      ...baseData,
      leftFragment,
      rightFragment,
    }
  }

  // left side is ready, right is loading or not picked
  if (leftFragment && !rightFragment) {
    const reason = rightQuery.isPending ? 'loading' : 'pickAnother'
    return {
      ...baseData,
      leftFragment,
      rightFragment: leftFragment,
      blankSide: {
        side: 'right',
        reason,
      },
    }
  }
  // right side is ready, left is loading or not picked
  if (rightFragment && !leftFragment) {
    const reason = leftQuery.isPending ? 'loading' : 'pickAnother'
    return {
      ...baseData,
      leftFragment: rightFragment,
      rightFragment,
      blankSide: {
        side: 'left',
        reason,
      },
    }
  }

  // last case is that neither side is picked
  return 'no-data'
}
