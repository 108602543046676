import { Modal as ModalComponent, ModalProps } from '@mantine/core'

import styles from './modal.module.scss'

const defaultProps: Partial<ModalProps> = {
  padding: 0,
  centered: true,

  closeOnClickOutside: false,
  // The overlay opacity transition takes half this time,
  // the modal body transition takes this full time
  transitionProps: {
    duration: 250,
  },
}

export const Modal = ModalComponent.extend({
  defaultProps,
  classNames: {
    root: styles.root,
    inner: styles.inner,
    content: styles.content,
    close: styles.close,
    header: styles.header,
    body: styles.body,
    title: styles.title,
  },
})
