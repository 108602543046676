import { Menu } from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { OrgOptionMap, OrgPermissionMap } from '@showrunner/codex'

import { FancyMenuItem } from '@components/FancyMenuItem'
import { showPushRundownToPrompterModal } from '@components/Modals'
import { showPrintScriptModal } from '@components/Modals/PrintScriptModal/PrintScriptModal'
import {
  ReadonlyRundownBanner,
  TrashedDocumentBanner,
} from '@components/ReadOnlyBanner'
import {
  printImmediately,
  showPrintPreferences,
} from '@components/RundownPrinting/PrintPreferences'
import { Toolbar } from '@components/Toolbar'
import { Keys, useDebouncedElementSize, useShortcuts } from '@hooks'
import { useMst } from '@state'
import { IRundown } from '@state/types'

import { showColumnVisibilityModal } from './ColumnVisibilityModal'
import { EditMenu } from './EditMenu'
import { showExportAsJson } from './ExportAsJsonModal'
import { InsertMenu } from './InsertMenu'
import { NumberingMenu } from './NumberingMenu'

const makeHiddenColumnLabel = (columnCount: number): string => {
  if (columnCount === 0) {
    return 'Hide columns'
  }
  const plural = columnCount > 1 ? 's' : ''
  return `${columnCount} hidden column${plural}`
}

export const RundownToolbar = observer(function RundownToolbar({
  rundown,
}: {
  rundown: IRundown
}) {
  const { currentOrg, rundownMap, user, view } = useMst()
  const { ref, width } = useDebouncedElementSize()

  const canEdit = rundown.isEditable && currentOrg
  const TOOLBAR_BREAKPOINT = canEdit ? 600 : 450
  const btnMode = width >= TOOLBAR_BREAKPOINT ? 'normal' : 'mini'

  const fakeWindows = view.isDebugEnabled('windows')

  const orgHasPrompter = currentOrg?.options.some(
    (c) => c.code === OrgOptionMap.PROMPTER_INTEGRATION && c.enabled,
  )
  const userCanPrompt = user.currentOrgPermissions.includes(
    OrgPermissionMap.PUSH_TO_PROMPTER,
  )

  const noScriptRows = rundown.orderedScripts.length < 1

  const openPushToPrompter = () => {
    if (orgHasPrompter && userCanPrompt) {
      showPushRundownToPrompterModal(rundown)
    }
  }

  const shortcutConfigs = {
    print: {
      keys: [Keys.CMD, 'P'],
      action() {
        showPrintPreferences(rundown)
      },
    },
    quickPrint: {
      keys: [Keys.CMD, Keys.ALT, 'P'],
      action() {
        printImmediately(rundown)
      },
    },
    pushToPrompter: {
      keys: [Keys.CMD, Keys.SHIFT, 'P'],
      action: openPushToPrompter,
      disabled: !userCanPrompt,
    },
  }

  const { menuProps, getItemProps } = useShortcuts(
    shortcutConfigs,
    fakeWindows ? { platform: 'win' } : {},
  )

  const hiddenColumnCount = rundown.hiddenScreenColumnCount
  const rundownListing = rundownMap.get(String(rundown.id))

  if (rundown.inTrash && rundownListing) {
    return (
      <TrashedDocumentBanner
        listing={rundownListing}
        canRestore={user.canEditRundowns}
      />
    )
  }

  return (
    <div ref={ref}>
      <Toolbar>
        <Toolbar.Section position="right">
          <EditMenu
            readonly={!canEdit}
            rundown={rundown}
            buttonMode={btnMode}
          />
          {canEdit && (
            <>
              <InsertMenu
                rundown={rundown}
                workspace={currentOrg}
                buttonMode={btnMode}
              />
              <NumberingMenu rundown={rundown} buttonMode={btnMode} />
            </>
          )}
          <Menu>
            <Menu.Target>
              <div>
                <Toolbar.Button
                  active={hiddenColumnCount > 0}
                  icon="fa-eye-slash"
                  onClick={() => showColumnVisibilityModal(rundown)}
                  label={makeHiddenColumnLabel(hiddenColumnCount)}
                  mode={btnMode}
                  dropdown
                />
              </div>
            </Menu.Target>
          </Menu>
          <Toolbar.Divider />
          <Menu {...menuProps} withinPortal>
            <Menu.Target>
              <div>
                <Toolbar.Button
                  label="Export"
                  icon="fa-share-from-square"
                  dropdown
                  mode={btnMode}
                />
              </div>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Label>Rundown</Menu.Label>
              <FancyMenuItem
                title="Print/Export PDF..."
                {...getItemProps('print')}
              />
              <FancyMenuItem
                title="Quick print"
                {...getItemProps('quickPrint')}
              />
              <FancyMenuItem
                title="Export as JSON..."
                onClick={() => showExportAsJson(rundown)}
              />
              <Menu.Divider />
              <Menu.Label>Scripts in Rundown</Menu.Label>
              <FancyMenuItem
                title="Print/Export PDF..."
                onClick={() =>
                  showPrintScriptModal({ docType: 'rundown', rundown })
                }
                disabled={noScriptRows}
              />

              {orgHasPrompter && (
                <>
                  <FancyMenuItem
                    title="Push to prompter..."
                    {...getItemProps('pushToPrompter')}
                  />
                </>
              )}
            </Menu.Dropdown>
          </Menu>
        </Toolbar.Section>
      </Toolbar>
      {!rundown.inTrash && !user.canEditRundowns && <ReadonlyRundownBanner />}
    </div>
  )
})
