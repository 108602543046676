import { ActionIcon, Tooltip } from '@mantine/core'
import { compile } from 'path-to-regexp'
import { useLocation } from 'wouter'

import { FaIcon } from '@components/FaIcon'
import { useMst } from '@hooks'
import { SnapshotViewType } from '@state/models/View/SnapshotLandState'
import { ROUTE_PATTERNS } from '@util'

const toSnapshotLand = compile<{ scriptId: string }>(
  ROUTE_PATTERNS.scriptHistory,
)

const snapshotLandPath = (scriptId: string, view?: SnapshotViewType) => {
  const path = toSnapshotLand({ scriptId })
  const search = view ? `?view=${view}` : ''
  return path + search
}

export const SnapshotLandIcon = ({ scriptId }: { scriptId: string }) => {
  const { user, location, view } = useMst()
  const [, setLocation] = useLocation()

  const handleClick = () => {
    view.snapshotLand.trackSnapshotEvent('ENTER_SNAPSHOT_LAND')
    view.explorerState.setLastScriptPath(location.pathname)
    setLocation(snapshotLandPath(scriptId, user.prefs.snapshotTab))
  }

  return (
    <Tooltip label="Snapshot history">
      <ActionIcon onClick={handleClick} color="dark.9">
        <FaIcon icon="fa-clock-rotate-left" />
      </ActionIcon>
    </Tooltip>
  )
}
