/**
 * Before we were on a version of Prosemirror that supported editing document
 * attributes collaboratively, we would create a top-level block that didn't
 * have a visual representation in order to store properties related to the
 * whole document.
 *
 * This plugin moves these properties to the document attribute when first
 * interacting with a script and deletes the FormatInfo block from the doc.
 */
import { Plugin, PluginKey, TextSelection } from 'prosemirror-state'

import { NodeTypeMap } from '@showrunner/codex'

import { parseOverrides } from '@util/formats'

import { PluginFactory } from './types'

const key = new PluginKey('removeFormatInfo')

export const removeFormatInfoPlugin: PluginFactory = () => {
  return new Plugin({
    key,
    appendTransaction(transactions, oldState, editorState) {
      const pos = oldState.doc.content.size - 1
      const nodeAtPos = oldState.doc.resolve(pos).node(1)
      const isFormatNode = nodeAtPos.type.name === NodeTypeMap.FORMAT_INFO

      if (!isFormatNode) return null

      const { tr } = editorState

      // We only ever made use of blockOverrides on FormatInfo on prod
      const blockOverrides = parseOverrides(nodeAtPos.attrs.blockOverrides)
      tr.setDocAttribute('blockOverrides', blockOverrides)

      tr.delete(pos - 1, pos)

      // Triggers a warning if our cursor is after the deleted element
      // https://github.com/ProseMirror/prosemirror-state/blob/09ffa0c8b5a331f6fbec2809b5bd454e4a43744d/src/selection.ts#L221
      tr.setSelection(TextSelection.near(tr.doc.resolve(pos - 1)))

      return tr
    },
  })
}
