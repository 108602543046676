import { Redirect, Route, Switch, useLocation } from 'wouter'

import { NotFoundPage } from '@components/ErrorPage'
import { OAuthCallbackPage } from '@components/OAuthCallbackPage'
import { SnapshotLand } from '@components/SnapshotLand'
import { SleepOverlay } from '@routes/components/SleepOverlay'
import { findConfigForPathname, ROUTE_PATTERNS } from '@util/pathConfigs'

import * as Layouts from '../layouts'

import * as components from './components'
import { SettingsRouter } from './SettingsRouter'
import { StaffRouter } from './StaffRouter'
import { WallabyRouter } from './util'

const sidebarRoutes = [
  ROUTE_PATTERNS.scriptEditor,
  ROUTE_PATTERNS.rundown,
  ROUTE_PATTERNS.root,
  ROUTE_PATTERNS.folder,
  ROUTE_PATTERNS.splitEditor,
  ROUTE_PATTERNS.archivedScript,
  ROUTE_PATTERNS.settings,
  ROUTE_PATTERNS.staffFlags,
  ROUTE_PATTERNS.staffFormatDetail,
  ROUTE_PATTERNS.staffFormats,
  ROUTE_PATTERNS.staffInvites,
  ROUTE_PATTERNS.staffLanding,
  ROUTE_PATTERNS.staffRundowns,
  ROUTE_PATTERNS.staffTemplateWorkspaces,
  ROUTE_PATTERNS.staffUsers,
  ROUTE_PATTERNS.staffUserDetail,
  ROUTE_PATTERNS.staffWorkspaces,
  ROUTE_PATTERNS.staffWorkspaceDetail,
  ROUTE_PATTERNS.staffCloneWorkspace,
]

/*
  Once we get to this router, we know the user has a current org
*/
const AppRouterInternal = () => {
  const [pathname] = useLocation()
  const currentRouteConfig = findConfigForPathname(pathname)

  return (
    <Layouts.AppWithHeader>
      {/* Header, Sidebar + body (script, rundown, new dashboard)
      Soon to be any authenticated route....
      */}
      <Route path={ROUTE_PATTERNS.scriptHistory} component={SnapshotLand} />

      <Route path={sidebarRoutes}>
        <Layouts.SidebarAndBody>
          <Switch>
            <Route
              path="/wayback/:scriptId"
              component={components.ArchivedScriptRoute}
            />
            <Route
              path={[
                ROUTE_PATTERNS.rundown,
                ROUTE_PATTERNS.splitEditor,
                ROUTE_PATTERNS.scriptEditor,
              ]}
              component={components.EditorsRoute}
            />
            <Route path="/" component={components.DashboardRoute} />
            <Route
              path="/folders/:folderId"
              component={components.DashboardRoute}
            />
            <Route path="/folders">
              <Redirect to="/" />
            </Route>
            <Route path={ROUTE_PATTERNS.scriptHistory} />
            {/* Delegate anything that starts with /settings here */}
            <Route path="/settings/:rest*" component={SettingsRouter} />
            <StaffRouter />
          </Switch>
        </Layouts.SidebarAndBody>
      </Route>

      {/* oauth callback for slack integration */}
      <Route path="/oauth/callback" component={OAuthCallbackPage} />

      {/* Not found page */}
      {!currentRouteConfig && (
        <Layouts.CenteredScrollingBody>
          <NotFoundPage />
        </Layouts.CenteredScrollingBody>
      )}
    </Layouts.AppWithHeader>
  )
}

// TODO: we can move more into the app router & stop with the switching
export const AppRouter = () => (
  <WallabyRouter>
    <SleepOverlay />
    <AppRouterInternal />
  </WallabyRouter>
)
