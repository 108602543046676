import { types } from 'mobx-state-tree'

import { IFolder } from '@state/types'
import { extractTsRestSuccess } from '@util/extractTsRest'

import { ScriptListingBase } from './ListingBase'

export const ScriptListing = ScriptListingBase.named('ScriptListing')
  .props({
    modelType: types.frozen<'scriptListing'>('scriptListing'),
    icon: types.string,
    formatId: types.string,
    isEditingName: false,
    pendingLocationRequest: false,
  })
  .views((self) => ({
    get path() {
      return `/scripts/${self.id}`
    },
    get isSelected(): boolean {
      return self.rootStore.location.getPathParam('scriptId') === self.id
    },
    get uuid() {
      return self.id
    },
  }))
  .actions((self) => ({
    getRootFolder(isPrivate: boolean, inTrash: boolean): IFolder | undefined {
      return self.rootStore.getRootFolder(isPrivate, inTrash)
    },
    setName(value: string) {
      self.name = value
    },
    setIsEditingName(value: boolean) {
      self.isEditingName = value
    },
    setPendingLocationRequest(value: boolean) {
      self.pendingLocationRequest = value
    },
  }))
  .actions((self) => ({
    async destroy() {
      await self.apiClient.destroyScript(self.id)
      self.rootStore.removeScriptFromState(self.id)
    },

    async duplicate(): Promise<void> {
      await self.rootStore.doDebug()
      await extractTsRestSuccess(
        self.scrapi.scripts.copy({
          params: { id: self.id },
          body: { name: `Copy of ${self.name}` },
        }),
        200,
      )

      const folder = self.rootStore.folderMap.get(self.folderId)
      const { currentOrg } = self.rootStore
      if (folder && currentOrg) {
        self.analytics.trackDocCreated({
          type: 'script',
          format: self.formatId,
          from: 'duplicate',
          accessLevel: folder.isPrivate ? 'private' : 'shared',
        })
      }
    },

    async moveToTrash() {
      await self.apiClient.trashScript({
        scriptId: self.id,
      })
    },

    async moveToFolder(folderId: string) {
      const response = await self.apiClient.moveScript({
        scriptId: self.id,
        folderId,
      })

      const isLoadedScript = self.rootStore.currentScript?.id === self.id
      if (response.status === 'LIMITED' || response.status === 'OPEN') {
        self.setSharedStatus(response.status)
        if (isLoadedScript) {
          self.rootStore.currentScript?.setSharedStatus(response.status)
        }
      }
    },

    async rename(name: string) {
      await extractTsRestSuccess(
        self.scrapi.scripts.updateScript({
          params: { id: self.id },
          body: { name },
        }),
        200,
      )
      self.setIsEditingName(false)
      self.setName(name)
      if (self.rootStore.currentScript?.id === self.id) {
        self.rootStore.currentScript.setName(name)
      }
    },
  }))
