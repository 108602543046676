import { schemas, ZInfer } from '@showrunner/scrapi'

import { ILoadedScript } from '@state'
import {
  FontCode,
  FormatBlockName,
  FormatOption,
  FormatOptionValue,
} from '@util/formats'

export type BulkChangeHandler = <Op extends FormatOption>(
  blockName: FormatBlockName,
  changes: Record<Op, FormatOptionValue<Op>>,
) => void

export type ConfigurableBlockType = ZInfer<
  typeof schemas.scriptFormats.StandardBlocks
>

export type BlockConfigurations = ZInfer<
  typeof schemas.scriptFormats.BlockConfigurations
>

export const changeEditorFontCode = ({
  code,
  script,
}: {
  code: FontCode
  script: ILoadedScript
}) => {
  const { editorState, editorView } = script.pmEditor
  if (!editorState || !editorView) return

  // when the format declares no font, courier prime is assumed
  const isDefault =
    code === (script.scriptFormat.definition.fontCode ?? 'courier-prime')

  // if the code isn't a genuine override, nullify
  const minifiedCode = isDefault ? null : code
  editorView.dispatch(editorState.tr.setDocAttribute('fontCode', minifiedCode))
}
