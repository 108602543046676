import type { QueryClient } from '@tanstack/react-query'
import type { Instance } from 'mobx-state-tree'
import type { EditorState, Selection } from 'prosemirror-state'
import type { EditorView } from 'prosemirror-view'

import { NodeTypeKey } from '@showrunner/codex'
import { schemas, ZInfer } from '@showrunner/scrapi'

import type { NamedServerConfig } from '@config'
import { LegacyApiClient, MixpanelClient, ScriptoApiClient } from '@util'
import { DatadogClient } from '@util/datadog'
import { LocalPersistence } from '@util/LocalPersistence'

import * as Models from './models'

// environment is shared by all mobx-state-tree models. These are
// created at app launch time and always available
export type Environment = {
  apiClient: ScriptoApiClient
  config: NamedServerConfig
  analytics: MixpanelClient
  localPersistence: LocalPersistence
  datadog: DatadogClient
  legacyApiClient: LegacyApiClient
  queryClient: QueryClient
}

export interface IRoot extends Instance<typeof Models.Root> {}
export interface IInvite extends Instance<typeof Models.Invite> {}
export interface IOrg extends Instance<typeof Models.Org> {}
export interface IOrgMember extends Instance<typeof Models.OrgMember> {}
export interface IOrgOptionModel
  extends Instance<typeof Models.OrgOptionModel> {}
export interface IUser extends Instance<typeof Models.User> {}
export interface IFolder extends Instance<typeof Models.Folder> {}
const COLLECTION_IDS = [
  'root',
  'favorites',
  'recentlyEdited',
  'myHistory',
] as const
export type CollectionId = (typeof COLLECTION_IDS)[number]
export function isCollectionId(val: string): val is CollectionId {
  return COLLECTION_IDS.some((id) => id === val)
}
export interface ILocation extends Instance<typeof Models.Location> {}
export interface IMembership extends Instance<typeof Models.Membership> {}
export interface ILoadedScript extends Instance<typeof Models.LoadedScript> {}
export interface IScriptListing extends Instance<typeof Models.ScriptListing> {}
export interface IRundownListing
  extends Instance<typeof Models.RundownListing> {}
export interface IInkProject extends Instance<typeof Models.InkProject> {}
export interface IRundown extends Instance<typeof Models.Rundown> {}
export interface IRundownRow extends Instance<typeof Models.RundownRow> {}
export interface IView extends Instance<typeof Models.View> {}
export interface IReadonlyFolderListState
  extends Instance<typeof Models.ReadonlyFolderListState> {}
export interface IPrompterPushCandidate
  extends Instance<typeof Models.PrompterPushCandidate> {}
export interface IPrompterSegment
  extends Instance<typeof Models.PrompterSegment> {}
export interface IPmEditor extends Instance<typeof Models.PmEditor> {}
export interface IPmDomInfo extends Instance<typeof Models.PmDomInfo> {}
export interface IPopulatedPmEditor extends IPmEditor {
  editorState: EditorState
  editorView: EditorView
  selection: Selection
}

export type IListing = IScriptListing | IRundownListing

export function isScriptListing(
  instance: IListing,
): instance is IScriptListing {
  return instance.modelType === 'scriptListing'
}
export function isRundown(instance: IListing): instance is IRundownListing {
  return instance.modelType === 'rundown'
}
export type IOpenedListing = IListing & { openedAt: Date }

export function isOpenedListing(
  instance: IListing,
): instance is IOpenedListing {
  return instance.openedAt !== undefined
}

export interface IRemoteCollaborator
  extends Instance<typeof Models.RemoteCollaborator> {}

export interface IScriptFormatSummary
  extends Instance<typeof Models.ScriptFormatSummaryModel> {}

// using Extract<> ensures that only plain text values in the union below
// that are confirmed to be valid appear in the subsequent type
// this is too clever and not clever enough simultaneously
export type NumberableNodeTypeKey = Extract<
  NodeTypeKey,
  'bracket' | 'sceneHeading' | 'slug' | 'dialogue'
>

export const EXPLORER_VIEW_TYPES = ['browser', 'outline']
export type ExplorerViewType = (typeof EXPLORER_VIEW_TYPES)[number]

// only formatting and collaborators truly have a micro mode
export type ButtonMode = 'normal' | 'mini' | 'micro'

export type ScriptReference = {
  scriptId: string
  name: string
  selectedInGrid?: boolean
}

export type SplitEditorPref = 'rows' | 'columns'

// shorthand to store ID of rundown or script. If the ID is a string
// it's a script, otherwise a rundown
export type ListingId = string | number

export type PermittedMembers = {
  id: string
  name: string
  email: string
  image: string | undefined
}[]

export type SharedScriptStatus = ZInfer<typeof schemas.SharedScriptStatus>

export type CursorCoords = XYCoords & { height: number }

export interface IComment extends Instance<typeof Models.CommentModel> {}
export interface ICommentData extends Instance<typeof Models.CommentData> {}

// this is a type we are missing in our server- it's the container for a
// list of comments
export type ICommentThread = Exclude<
  ReturnType<ICommentData['getThread']>,
  undefined
>

export type CreateScriptFromRundownParams = {
  name: string
  formatId: string
  folderId: string
}

type RawElementNumberSummary = {
  id: string | null
  elementNumber: string | null
}

export type ElementNumberSummary = {
  id: string
  elementNumber: string | null
  offset: number
}

export function isIdentifiableSummary(
  block: RawElementNumberSummary,
): block is ElementNumberSummary {
  return block.id !== null
}

export type WorkspaceItem = ZInfer<typeof schemas.WorkspaceItem>
export type ScriptItem = ZInfer<typeof schemas.ScriptItem>
export type RundownItem = ZInfer<typeof schemas.RundownItem>
export type FolderItem = ZInfer<typeof schemas.FolderItem>

export type ItemPaging = ZInfer<typeof schemas.ItemPaging>
