import React from 'react'

import cn from 'classnames'
import { observer } from 'mobx-react-lite'

import { getConfigData } from '@choo-app/lib/editor/plugins/configData'
import { getSelFormatBlockName } from '@components/EditorToolbar/Formatting/SpacingMenu/helpers'
import { useMst } from '@hooks'
import {
  isSingleBlockTextSelection,
  selTouchesDualDialogue,
  setHorizontalMarginAttrs,
} from '@util'

import {
  buildTicks,
  ConfiguredMarginValue,
  RulerConfig,
  rulerConfigFromLayout,
} from './helpers'
import { MarginSlider } from './MarginSlider'

import styles from './MarginRuler.module.scss'

// we round to the nearest whole (ch) value before comparing
// and return null if those values are equal to remove the override entirely
const nullIfEqualish = (fresh: number, existing: number): number | null =>
  Math.round(fresh) === Math.round(existing) ? null : fresh

type MarginRulerProps = {
  config: RulerConfig
  margins?: ConfiguredMarginValue
  onOverrideMargins?: (value: ConfiguredMarginValue) => void
  disabled?: boolean
}

const Tick = ({ label }: { label?: string }) => (
  <span className={cn(styles.tick, { [styles.majorTick]: label })}>
    {label && <span className={styles.tickLabel}>{label}</span>}
  </span>
)

// exported for storybooks
export const MarginRulerComponent = function (props: MarginRulerProps) {
  const { displayedUnitSize, ticksPerUnit } = props.config

  const ticks = React.useMemo(
    () => buildTicks({ displayedUnitSize, ticksPerUnit }),
    [displayedUnitSize, ticksPerUnit],
  )

  const tickWidthPx = props.config.unscaledWidthPx / ticks.length

  return (
    <div
      className={styles.marginRuler}
      style={{
        width: props.config.unscaledWidthPx,
        '--tick-width': `${tickWidthPx}px`,
      }}
    >
      <MarginSlider
        constraints={props.config.allowedRange}
        unscaledWidthPx={props.config.unscaledWidthPx}
        margins={props.margins}
        onChange={props.onOverrideMargins}
        disabled={props.disabled}
      />
      {ticks.map(({ label }, idx) => (
        <Tick label={label} key={idx} />
      ))}
    </div>
  )
}

// this is an observable component that relies on mst.currentScript. Ideally
// we would pass a script in but the script wrappers are really gnarly
// and it's not the easiest place to do that
export const MarginRuler = observer(function MarginRuler() {
  const { currentScript, socketManager } = useMst()

  if (!(currentScript && currentScript.pmEditor.editorView)) {
    return null
  }

  const { editorView, editorState } = currentScript.pmEditor

  // TODO: do we have a one-stop-shop for whether or not the script is
  // CURRENTLY editable?
  const disabled =
    !currentScript.isEditable ||
    currentScript.pmEditor.editabilityLost ||
    !socketManager.connected ||
    !editorState ||
    !isSingleBlockTextSelection(editorState) ||
    selTouchesDualDialogue(editorState)

  const blockName = getSelFormatBlockName(
    currentScript.pmEditor.editorView.state,
  )

  if (!(blockName && editorState)) return null

  // Use the blockId as a key to recreate the ruler whenever we change
  // blocks. This simplifies the logic for buffering changes originating
  // from the ruler
  const blockId = String(editorState.selection.$from.node(2).attrs.id)
  const { currentBlockFormats } = getConfigData(editorState)
  const blockFormat = currentBlockFormats[blockName]

  const { attrs } =
    currentScript.pmEditor.editorView.state.selection.$from.parent

  const marginLeft = attrs?.marginLeft ?? blockFormat.marginLeft
  const width = attrs?.width ?? blockFormat.width

  const handleOverride = (override: ConfiguredMarginValue) => {
    const ml = nullIfEqualish(
      override.marginLeft,
      currentScript.currentFormatValue(blockName, 'marginLeft'),
    )
    const w = nullIfEqualish(
      override.width,
      currentScript.currentFormatValue(blockName, 'width'),
    )
    setHorizontalMarginAttrs({ marginLeft: ml, width: w, editorView })
    currentScript.trackEvent('PARAGRAPH_MARGINS_CHANGED')
  }

  return (
    <MarginRulerComponent
      key={blockId}
      config={rulerConfigFromLayout(currentScript.pageLayout)}
      margins={{ marginLeft, width }}
      onOverrideMargins={handleOverride}
      disabled={disabled}
    />
  )
})

// this handles the scaling and positioning needed to
// have the margin ruler work in the PrintPreviewWrapper
export const StickyRulerWrapper = ({
  zoomLevel,
  positioning,
  leftAffordance,
  children,
}: {
  // left is used when there's horizontal scrolling
  positioning: 'left' | 'center'
  // when left, this is the unscaled value that pads the
  // left side of the script (to accommodate avatars, etc)
  leftAffordance: number
  zoomLevel: number
  // this is meant to be a MarginRuler
  children: React.ReactNode
}) => {
  const marginLeft =
    positioning === 'left' ? leftAffordance * zoomLevel : undefined
  return (
    <div
      className={cn(styles.marginRulerWrapper, {
        [styles.leftAligned]: positioning === 'left',
      })}
      style={{
        '--ruler-zoom': zoomLevel,
        marginLeft,
      }}
    >
      {children}
    </div>
  )
}
