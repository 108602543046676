import { observer } from 'mobx-react-lite'

import { ExplorerView } from '@components/ExplorerView'
import { useMst } from '@state'

import { DocumentOutline } from './DocumentOutline'
import { RundownOutline } from './RundownOutline'

const RUNDOWN_OUTLINE = 'rundown-outline'
const SCRIPT_OUTLINE = 'script-outline'

export const DocumentOutlineExplorer = observer(
  function DocumentOutlineExplorer() {
    const { view, currentRundown, currentScript, location } = useMst()
    const { isSectionCollapsed, toggleSectionCollapsed } = view.explorerState

    // we'll show collapse/expand if we have both rundown and script
    const isScriptRoute = !!location.getPathParam('scriptId')
    const isRundownRoute = !!location.getPathParam('rundownId')
    const dualMode = isScriptRoute && isRundownRoute

    const getExpandIcon = (
      section: string,
    ): 'point-right' | 'point-down' | undefined => {
      if (!dualMode) {
        return undefined
      }
      return isSectionCollapsed(section) ? 'point-right' : 'point-down'
    }

    return (
      <ExplorerView>
        {isRundownRoute && (
          <ExplorerView.Header
            title="Scripts in Rundown"
            expandIcon={getExpandIcon(RUNDOWN_OUTLINE)}
            onClick={() => toggleSectionCollapsed(RUNDOWN_OUTLINE)}
            enableCollapse={dualMode}
          />
        )}
        {isRundownRoute && (
          <ExplorerView.Content
            sectionId={RUNDOWN_OUTLINE}
            enableCollapse={dualMode}
          >
            {currentRundown && <RundownOutline rundown={currentRundown} />}
          </ExplorerView.Content>
        )}
        {isScriptRoute && (
          <ExplorerView.Header
            enableCollapse={dualMode}
            title="Script Outline"
            expandIcon={getExpandIcon(SCRIPT_OUTLINE)}
            onClick={() => toggleSectionCollapsed(SCRIPT_OUTLINE)}
          />
        )}
        {isScriptRoute && (
          <ExplorerView.Content
            sectionId={SCRIPT_OUTLINE}
            enableCollapse={dualMode}
          >
            {currentScript && (
              <DocumentOutline
                script={currentScript}
                allowLinkDragging={currentScript.canReorderSections}
              />
            )}
          </ExplorerView.Content>
        )}
      </ExplorerView>
    )
  },
)
