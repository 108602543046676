import React from 'react'

import cn from 'classnames'

import { FragmentDiff } from '@util/diffing/FragmentDiff'
import {
  isLineDiff,
  isOmitted,
  isWordDiff,
  SideBySideDiffLine,
} from '@util/diffing/sideBySide'
import { appendFontFallback, FontCodeMap } from '@util/formats'

import { SelectedSideIcon } from '../../SelectedSideIcon'
import { PopulatedSideBySideData } from '../../useSideBySideData'
import { BodyPlaceholder } from '../BodyPlaceholder'
import { IdenticalSnapshotsToast } from '../IdenticalSnapshotsToast'

import { DATA_DIFF_PROPS, useDiffSelection } from './useDiffSelection'

import './SideBySideDiff.scss'

const Row = ({
  className,
  children,
}: {
  className?: string
  children: React.ReactNode
}) => (
  <div {...DATA_DIFF_PROPS.row} className={cn('sbs-diff_row', className)}>
    {children}
  </div>
)

const LeftSide = ({
  className,
  children,
}: {
  className?: string
  children: React.ReactNode
}) => {
  return (
    <div
      {...DATA_DIFF_PROPS.left}
      className={cn('sbs-diff_column', 'sbs-diff_column___left', className)}
    >
      {children}
    </div>
  )
}

const RightSide = ({
  className,
  children,
}: {
  className?: string
  children: React.ReactNode
}) => {
  return (
    <div
      {...DATA_DIFF_PROPS.right}
      className={cn('sbs-diff_column', 'sbs-diff_column___right', className)}
    >
      {children}
    </div>
  )
}

const LineBreak = () => (
  <i
    className={cn(
      'sbs-diff_change',
      'sbs-diff_linebreak',
      'fa fa-turn-down-left',
    )}
  />
)

const OmittedRow = ({ count }: { count: number }) => {
  const s = count > 1 ? 's' : ''
  const text = `${count} row${s} omitted`
  return (
    <Row>
      <LeftSide className="sbs-diff_omission">{text}</LeftSide>
      <RightSide className="sbs-diff_omission">{text}</RightSide>
    </Row>
  )
}

const SideTitle = ({
  side,
  title,
  subtitle,
  sleneName,
  hasEmphasis,
}: {
  side: LeftRight
  title: string
  subtitle: string
  sleneName?: string
  hasEmphasis?: boolean
}) => (
  <div className="sbs-diff_column___header">
    <SelectedSideIcon side={side} />
    {sleneName && <div className="sbs-diff_slene">{sleneName}</div>}
    <span className={cn({ 'sbs-diff_title__emphasized': hasEmphasis })}>
      {title}
    </span>
    &nbsp;
    <span className="sbs-diff_subtitle">{subtitle}</span>
  </div>
)

const SideBySideRow = ({ line }: { line: SideBySideDiffLine }) => {
  if (isOmitted(line)) {
    return <OmittedRow count={line.count} />
  }

  if (isLineDiff(line)) {
    const { side, value } = line

    return (
      <Row>
        <LeftSide>
          <span className={cn({ 'sbs-diff_change___left': side === 'left' })}>
            {side === 'left' ? value : ''}
          </span>
          {line.newline === 'left' && <LineBreak />}
        </LeftSide>
        <RightSide>
          <span className={cn({ 'sbs-diff_change___right': side === 'right' })}>
            {side === 'right' ? value : ''}
          </span>
          {line.newline === 'right' && <LineBreak />}
        </RightSide>
      </Row>
    )
  }

  if (isWordDiff(line)) {
    const { left, right } = line

    return (
      <Row>
        <LeftSide>
          {left.map(({ text, changed }, index) => (
            <span
              key={index}
              className={cn({ 'sbs-diff_change___left': changed })}
            >
              {text}
            </span>
          ))}
          {line.newline === 'left' && <LineBreak />}
        </LeftSide>
        <RightSide>
          {right.map(({ text, changed }, index) => (
            <span
              key={index}
              className={cn({ 'sbs-diff_change___right': changed })}
            >
              {text}
            </span>
          ))}
          {line.newline === 'right' && <LineBreak />}
        </RightSide>
      </Row>
    )
  }

  // we've type narrowed to unchanged
  return (
    <Row>
      <LeftSide>
        {line.text}
        {line.newline === 'left' && <LineBreak />}
      </LeftSide>
      <RightSide>
        {line.text}
        {line.newline === 'right' && <LineBreak />}
      </RightSide>
    </Row>
  )
}

type SideBySideDiffProps = PopulatedSideBySideData & {
  selectionSide?: LeftRight
  linesOfContext: number | null
  monochrome: boolean
}

export const SideBySideDiff = ({
  leftFragment,
  rightFragment,
  leftActiveSlene,
  leftTitle,
  leftSubtitle,
  leftEmphasis,
  rightActiveSlene,
  rightTitle,
  rightSubtitle,
  rightEmphasis,
  linesOfContext,
  monochrome,
  blankSide,
  fontCode,
}: SideBySideDiffProps) => {
  const { selectionSide } = useDiffSelection()
  const diff = new FragmentDiff({
    left: leftFragment,
    right: rightFragment,
  })

  // if one side is blank, there's no diff so show the full document
  // otherwise, respect the user preference
  const contextToUse = blankSide ? null : linesOfContext
  const lines = diff.getSideBySideDiff(contextToUse)

  return (
    <div
      id="side-by-side-wrapper"
      className={cn('sbs-diff', {
        'sbs-diff___monochrome': monochrome,
        'sbs-diff_left-select': selectionSide === 'left',
        'sbs-diff_right-select': selectionSide === 'right',
      })}
      style={{
        '--script-font-family': appendFontFallback(FontCodeMap[fontCode]),
      }}
    >
      {diff.identical && !blankSide && <IdenticalSnapshotsToast mb={15} />}

      <div className="sbs-diff_titlerow">
        <div className="sbs-diff_titlecell">
          {blankSide?.side === 'left' ? (
            <BodyPlaceholder fill variant={blankSide.reason} />
          ) : (
            <SideTitle
              side="left"
              title={leftTitle}
              subtitle={leftSubtitle}
              sleneName={leftActiveSlene?.label}
              hasEmphasis={leftEmphasis}
            />
          )}
        </div>
        <div className="sbs-diff_titlecell">
          {blankSide?.side === 'right' ? (
            <BodyPlaceholder fill variant={blankSide.reason} />
          ) : (
            <SideTitle
              side="right"
              title={rightTitle}
              subtitle={rightSubtitle}
              sleneName={rightActiveSlene?.label}
              hasEmphasis={rightEmphasis}
            />
          )}
        </div>
      </div>

      <div
        className={cn({
          'sbs-diff_hide-left': blankSide?.side === 'left',
          'sbs-diff_hide-right': blankSide?.side === 'right',
        })}
      >
        {lines.map((line, index) => (
          <SideBySideRow line={line} key={index} />
        ))}
      </div>
    </div>
  )
}
