export const EVENTS = {
  APP_STARTED: 'app started',
  SIGNUP_START: 'signup start',
  SIGNUP: 'signup',
  CREATE_SHOW_START: 'create show start',
  SHOW_CREATED: 'show created',
  INVITE_SENT: 'invite sent',
  INVITE_REVOKED: 'invite revoked',
  SHOW_JOINED: 'show joined',
  INVITE_REQUESTED_NEW_SHOW: 'user requested invite to create new show',
  SHOW_REMOVED: 'contributor removed from show',
  SHOW_REMOVED_SELF: 'member removed self from show',
  SHOW_RETIRED: 'workspace retired by owner',
  BETA_CREATE_FOLDER: 'beta create folder',
  BETA_RENAME_SCRIPT: 'beta rename script',
  BETA_RENAME_FOLDER: 'beta rename folder',
  BETA_MOVE_SCRIPT: 'beta move script',
  BETA_MOVE_FOLDER: 'beta move folder',
  BETA_TRASH_SCRIPT: 'beta trash script',
  BETA_TRASH_FOLDER: 'beta trash folder',
  BETA_DELETE_SCRIPT: 'beta permanently delete script',
  BETA_SWITCH_SHOW: 'beta switch show',
  BETA_IMPORTED_FDX: 'beta script fdx import',
  BETA_EXPORTED_FDX: 'beta script fdx export',
  BETA_EXPORTED_SNAPSHOT_FDX: 'beta snapshot fdx export',
  BETA_EXPORTED_PDF: 'beta script pdf export',
  EXPORTED_SECTION_PDF: 'section pdf export',
  BETA_SCRIPT_OPENED: 'beta script opened',
  BETA_SCRIPT_CLOSED: 'beta script closed',
  BETA_EDITOR_SHORTCUTS_OPENED: 'beta editor shortcuts opened',
  SCRIPT_SHARED: 'private script shared with org',
  SCRIPT_TYPE_SELECTED: 'script type selected',
  SCRIPT_EXPORTED_TXT: 'script prompter export',
  SCRIPT_EXPORTED_FOUNTAIN: 'script fountain export',
  SCRIPT_EXPORTED_RUNDOWN: 'script rundown export',
  SCRIPT_EXPORTED_CHARACTER_REPORT: 'script character report export',
  SCRIPT_VERSION_CREATED: 'script version created',
  SCRIPT_VERSION_EXPORTED_FDX: 'script version fdx export',
  SCRIPT_VERSION_EXPORTED_TXT: 'script version prompter export',
  SCRIPT_VERSION_EXPORTED_FOUNTAIN: 'script version fountain export',
  SCRIPT_HISTORY_FEED_OPENED: 'script snapshot history feed opened',
  SCRIPT_COMMENT_CREATED: 'script comment created',
  SCRIPT_COMMENT_DELETED: 'script comment deleted',
  SCRIPT_COMMENT_EDITED: 'script comment edited',
  SCRIPT_COMMENT_RESOLVED: 'script comment resolved',
  SCRIPT_COMMENT_UNRESOLVED: 'script comment unresolved',
  SCRIPT_COMMENT_FEED_OPENED: 'script comment feed opened',
  SCRIPT_NAV_REORDERED: 'script nav reordered',
  SCRIPT_SLACK_INTEGRATION_ENABLED: 'script slack integration enabled',
  SCRIPT_SLACK_INTEGRATION_DISABLED: 'script slack integration disabled',
  SCRIPT_COMPARE_OPENED: 'script compare opened',
  SCRIPT_COMPARE_FAILED: 'script compare failed',
  SCRIPT_ELEMENTS_NUMBERED: 'script elements numbered',
  SCRIPT_ELEMENT_NUMBERING_OVERLAP:
    'script element numbering risked content overlap',
  SCRIPT_BLOCK_FORMATTING_CUSTOMIZED: 'script block formatting customized',
  SCRIPT_FONT_CUSTOMIZED: 'script font customized',
  SCRIPT_PRODUCTION_MENU_OPENED: 'production menu opened',
  SCRIPT_BRACKETS_IN_NAV_TOGGLED: 'brackets toggled in script navigation',
  SCRIPT_STATUS_CHANGED: 'script status changed',
  EDITOR_WRITING_ACTIVITY: 'script activity',
  EDITOR_PRESS_ENTER: 'pressed enter in script',
  EDITOR_CREATE_DUAL_DIALOGUE: 'dual dialogue created',
  ADMIN_PROMOTED: 'contributor promoted to admin',
  ADMIN_DEMOTED: 'admin demoted to contributor',
  ORG_OPTION_ENABLED: 'org option enabled',
  ORG_OPTION_DISABLED: 'org option disabled',
  ORG_RENAMED: 'org renamed',
  ORG_OWNERSHIP_TRANSFER: 'org assigned a new owner',
  UPGRADE_INQUIRY_SENT: 'upgrade inquiry sent',
  SET_ZOOM_LEVEL: 'document zoomed',
  PARAGRAPH_ALIGNMENT_CHANGED: 'paragraph alignment changed',
  PARAGRAPH_MARGINS_CHANGED: 'paragraph margins changed',
  PARAGRAPH_SPACE_ABOVE_CHANGED: 'paragraph space above changed',
  PARAGRAPH_SPACING_CHANGED: 'paragraph spacing changed',

  DOCUMENT_CREATED: 'document created',
  DOCUMENT_OPENED: 'document opened',
  DOCUMENT_MODIFIED: 'document modified',

  LOGIN: 'login',
  LOGOUT: 'logout',
  ENABLED_PAGELESS_MODE: 'enabled pageless mode',
  DISABLED_PAGELESS_MODE: 'disabled pageless mode',
  SIDEBAR_OPENED: 'sidebar opened',
  PROMPTER_PUSH_SCRIPT: 'script pushed to prompter',
  PROMPTER_PUSH_RUNDOWN: 'rundown pushed to prompter',

  ENTER_SPLIT_VIEW: 'enter split view',
  EXIT_SPLIT_VIEW: 'exit split view',
  CHANGE_SPLIT_VIEW: 'change split view',

  PROMPTER_VIEW_OPENED: 'prompter view opened',
  PROMPTER_VIEW_CLOSED: 'prompter view closed',
  PROMPTER_VIEW_CHANGED: 'prompter view changed',

  ADVANCED_SEARCH_OPENED: 'advanced search popover opened',

  // snapshot-land events
  DISMISS_SELECTED_SNAPSHOT: 'dismiss selected snapshot',
  ENTER_SNAPSHOT_LAND: 'enter snapshot land',
  LOAD_MORE_SNAPSHOTS: 'load more snapshots',
  SELECT_SNAPSHOT: 'select snapshot',
  SELECT_SNAPSHOT_TAB: 'select snapshot tab',
  SHOW_SLENE_PICKER: 'show slene picker',
  SCRIPT_VERSION_EXPORTED_PDF: 'script version pdf export',
  SCRIPT_VERSION_PRINTED: 'script version printed',
  SCRIPT_REVISION_EXPORTED: 'script pdf export with revision asterisks',
  SIDE_BY_SIDE_EXPORTED: 'side by side comparison exported to pdf',
  SCRIPT_REVISION_PRINTED: 'script with revision asterisks printed',
  SIDE_BY_SIDE_PRINTED: 'side by side comparison printed',
}

export type MixpanelEventName = keyof typeof EVENTS
