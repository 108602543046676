import React from 'react'

import { Group, UnstyledButton } from '@mantine/core'

import styles from './MiniStepper.module.scss'

export interface MiniStepperProps {
  active: number
  steps: string[]
  onStepClick: (index: number) => void
}

export function MiniStepper(props: MiniStepperProps) {
  return (
    <Group gap={5} role="tablist" aria-label="Step progress">
      {props.steps.map((step, index) => (
        <UnstyledButton
          key={step}
          onClick={() => props.onStepClick(index)}
          className={styles.miniStepper_step}
          role="tab"
          aria-selected={index === props.active}
          aria-label={`Step ${index + 1} of ${props.steps.length}`}
        />
      ))}
    </Group>
  )
}
