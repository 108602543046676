import { z } from 'zod'

import { schemas, ZInfer } from '@showrunner/scrapi'

const standardBlockValidator = schemas.scriptFormats.StandardBlockConfig

export type StandardBlockConfig = ZInfer<
  typeof schemas.scriptFormats.StandardBlockConfig
>

export type ScriptFormatConfiguration = ZInfer<
  typeof schemas.scriptFormats.ScriptFormatDefinition
>

const formatWithConfiguration =
  schemas.scriptFormats.ScriptFormatDefinition.required({ pageConfig: true })
// stricter type that makes sure pageConfig is populated
export type ScriptFormatWithPageConfig = ZInfer<typeof formatWithConfiguration>

export type ScriptFormatBlockConfiguration = ScriptFormatConfiguration['blocks']
export type BlockFormats = ScriptFormatBlockConfiguration

export type FormatBlockName = keyof ScriptFormatBlockConfiguration

export function isFormatBlockName(value: string): value is FormatBlockName {
  return schemas.scriptFormats.ScriptFormatDefinition.shape.blocks
    .keyof()
    .options.includes(value as FormatBlockName)
}

const formatKeyEnum = schemas.scriptFormats.StandardBlockConfig.keyof()
export type FormatOption = ZInfer<typeof formatKeyEnum>

export type FormatOptionValue<T extends FormatOption> = ZInfer<
  typeof schemas.scriptFormats.StandardBlockConfig
>[T]

// this parser inspects unsaved overrides that are merged with script format defaults
// and adds an additional check to confirm that blocks aren't ever narrower than 1"

// further down the pike we minify unsaved overrides and parse them in a way
// that swaps in undefined when invalid content is detected
export const enhancedBlockOverride = standardBlockValidator.extend({
  width: z.number().min(10),
})

const standardBlockOverride = standardBlockValidator
  .partial()
  .optional()
  // if there's an error in the overrides, just ignore all the overrides
  // for the block
  .catch({})
export type SingleBlockOverride = z.infer<typeof standardBlockOverride>

const standardBlockDefinedOverride = standardBlockValidator
  .partial()
  // if there's an error in the overrides, set an empty object
  .catch({})

export type DefinedSingleBlockOverride = z.infer<
  typeof standardBlockDefinedOverride
>

export const blockOverridesParser = z
  .object({
    general: standardBlockOverride,
    new_act: standardBlockOverride,
    end_of_act: standardBlockOverride,
    action: standardBlockOverride,
    sceneHeading: standardBlockOverride,
    transition: standardBlockOverride,
    slug: standardBlockOverride,
    bracket: standardBlockOverride,
    dialogue: standardBlockOverride,
    character: standardBlockOverride,
    parenthetical: standardBlockOverride,
    dual_dialogue: standardBlockOverride,
    dual_dialogue_column: standardBlockOverride,
  })
  .partial()

export type BlockOverrides = z.infer<typeof blockOverridesParser>

export const parseOverrides = (input: unknown): BlockOverrides => {
  if (typeof input === 'object' && !!input) {
    const parsed = blockOverridesParser.safeParse(input)
    if (parsed.success) {
      return parsed.data
    }
  }
  return {}
}

// strip away any overrides for a single block that match the
// base format
export const extractDifferences = (
  baseConfig: z.infer<typeof standardBlockValidator>,
  override: SingleBlockOverride,
): SingleBlockOverride | undefined => {
  if (!override) {
    return undefined
  }
  const result: SingleBlockOverride = {}
  standardBlockValidator.keyof().options.forEach((key) => {
    const newValue = override[key]
    const baseValue = baseConfig[key]
    if (newValue !== baseValue && newValue !== undefined && newValue !== '') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      result[key] = newValue as any
    }
  })
  return Object.keys(result).length > 0 ? result : undefined
}

export const minifyOverrides = (
  baseFormat: BlockFormats,
  overrides: BlockOverrides,
): BlockOverrides => {
  const result: BlockOverrides = {}
  blockOverridesParser.keyof().options.forEach((key) => {
    const diffs = extractDifferences(baseFormat[key], overrides[key])
    if (diffs) {
      result[key] = diffs
    }
  })
  return result
}

export type PageConfig = z.infer<typeof schemas.scriptFormats.PageConfig>

export const parsePageConfig = (value: unknown): Partial<PageConfig> => {
  const parsed = schemas.scriptFormats.PageConfig.safeParse(value)
  if (parsed.success) {
    return parsed.data
  }
  return {}
}

export type FontCode = z.infer<typeof schemas.scriptFormats.FontCode>

export const FontCodeMap: Record<FontCode, string> = {
  'courier-prime': 'Courier Prime',
  'courier-prime-sans': 'Courier Prime Sans',
}

export const appendFontFallback = (font: string) =>
  `"${font}", courier, monospace`
