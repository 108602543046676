import { schemas, ZInfer } from '@showrunner/scrapi'

import { BaseModel } from '@state/models/BaseModel'
import { reloadWindow } from '@util'
import { authToken } from '@util/authToken'

type LoggedInStatus = {
  loggedIn: true
  userId: string
}

type LoggedOutStatus = {
  loggedIn: false
}

type AuthErrorCode = ZInfer<typeof schemas.AuthErrorCode>

export type LoginResult =
  | {
      success: true
    }
  | {
      success: false
      code: AuthErrorCode
    }

type AuthStatus = LoggedInStatus | LoggedOutStatus

/*
  This model wraps the calls to login, logout and check auth status to handle
  all the variations of scrapi vs. api and making sure that the in-memory
  singleton auth token gets populated properly
*/
export const AuthManager = BaseModel.named('AuthManager').actions((self) => ({
  _onLoggedIn(token: string) {
    self.environment.localPersistence.markLoggedIn(true)
    authToken.set(token)
  },

  async getAuthStatus(): Promise<AuthStatus> {
    const response = await self.scrapi.auth.status()
    if (response.status === 200) {
      if (response.body.loggedIn) {
        const { socketToken, id } = response.body
        this._onLoggedIn(socketToken)
        return { loggedIn: true, userId: id }
      }
      // only clear the token on an explicit failure, we might have gotten
      // another kind of failure
      authToken.clear()
    }
    return { loggedIn: false }
  },

  async login(args: { email: string; password: string }): Promise<LoginResult> {
    const response = await self.scrapi.auth.login({ body: args })
    if (response.status === 200) {
      this._onLoggedIn(response.body.socketToken)
      return { success: true }
    } else if (response.status === 420) {
      return {
        success: false,
        code: response.body.code,
      }
    } else {
      return {
        success: false,
        code: 'UNEXPECTED_ERROR',
      }
    }
  },

  async logout() {
    const response = await self.scrapi.auth.logout({ body: {} })
    // make scrapi throw if this fails since we haven't successfully
    // logged out
    if (response.status !== 200) {
      throw new Error('Unknown server error', { cause: 200 })
    }

    authToken.clear()
    self.environment.localPersistence.markLoggedIn(false)
    self.trackEvent('LOGOUT')
    await reloadWindow('/login')
  },
}))
