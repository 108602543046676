import { roundToPrecision } from '@util/math'

const PX_PER_CM = 37.8
const PX_PER_IN = 96
const PX_PER_CH = 9.6
const CH_PER_IN = 10
const CH_PER_CM = 10 / 2.54
const IN_PER_CM = 2.54

export class Unit {
  constructor(
    readonly value: number,
    readonly unit: 'in' | 'cm' | 'px' | 'ch',
  ) {}

  toRounded(precision: 1 | 2 | 3 = 2): number {
    return roundToPrecision(this.value, precision)
  }

  toRoundedCssValue(precision: 1 | 2 | 3 = 2): string {
    return `${this.toRounded(precision)}${this.unit}`
  }

  private get inchValue() {
    switch (this.unit) {
      case 'in':
        return this.value
      case 'cm':
        return this.value / IN_PER_CM
      case 'px':
        return this.value / PX_PER_IN
      case 'ch':
        return this.value / CH_PER_IN
    }
  }

  private get pxValue() {
    switch (this.unit) {
      case 'in':
        return this.value * PX_PER_IN
      case 'cm':
        return this.value * PX_PER_CM
      case 'px':
        return this.value
      case 'ch':
        return this.value * PX_PER_CH
    }
  }

  private get chValue() {
    switch (this.unit) {
      case 'in':
        return this.value * CH_PER_IN
      case 'cm':
        return this.value * CH_PER_CM
      case 'px':
        return this.value / PX_PER_CH
      case 'ch':
        return this.value
    }
  }

  private get cmValue() {
    switch (this.unit) {
      case 'in':
        return this.value * IN_PER_CM
      case 'cm':
        return this.value
      case 'px':
        return this.value / PX_PER_CM
      case 'ch':
        return this.value / CH_PER_CM
    }
  }

  toInches() {
    return new Unit(this.inchValue, 'in')
  }

  toPx() {
    return new Unit(this.pxValue, 'px')
  }

  toCh() {
    return new Unit(this.chValue, 'px')
  }

  toCm() {
    return new Unit(this.cmValue, 'cm')
  }
}

export const inches = (value: number) => new Unit(value, 'in')
export const centimeters = (value: number) => new Unit(value, 'cm')
export const chars = (value: number) => new Unit(value, 'ch')
export const pixels = (value: number) => new Unit(value, 'px')
