import React from 'react'

import {
  Button,
  Group,
  Select,
  Space,
  Stack,
  Table,
  Text,
  TextInput,
} from '@mantine/core'
import { v4 as isUUID } from 'is-uuid'

import { schemas, ZInfer } from '@showrunner/scrapi'

import { FaIcon } from '@components/FaIcon'
import { showError } from '@components/Modals'
import { NavAnchor } from '@components/NavAnchor'
import { PaginatedPayloadView } from '@components/PaginatedPayloadView'
import { CopyableId } from '@components/Staff/CopyableId'
import { useMst } from '@state'
import { WorkspaceAccountStatus } from '@util/ScriptoApiClient/types'

import { useFetchBetaFlags } from './useFetchBetaFlags'

import styles from './Workspaces.module.scss'

type WorkspaceFilter = Omit<
  ZInfer<typeof schemas.FindWorkspacesRequest>,
  'from' | 'size'
>

// Convenience type for any kind of event that has a preventDefault on it
type StoppableEvent = { preventDefault: () => void }

const WorkspacesResultsView = ({
  results,
}: {
  results: ZInfer<typeof schemas.StaffFindWorkspacesResponse>['results']
}) => (
  <Table>
    <Table.Thead>
      <Table.Tr>
        <Table.Th>Name</Table.Th>
        <Table.Th>Status</Table.Th>
        <Table.Th>Tier</Table.Th>
        <Table.Th>Member count</Table.Th>
        <Table.Th>ID</Table.Th>
      </Table.Tr>
    </Table.Thead>
    <Table.Tbody>
      {results.map((w) => (
        <Table.Tr key={w.id}>
          <Table.Td>
            <NavAnchor href={`/staff/workspaces/${w.id}`}>{w.name}</NavAnchor>
          </Table.Td>
          <Table.Td>{w.accountStatus}</Table.Td>
          <Table.Td>{w.tier}</Table.Td>
          <Table.Td>{w.memberCount}</Table.Td>
          <Table.Td>
            <CopyableId id={w.id} />
          </Table.Td>
        </Table.Tr>
      ))}
    </Table.Tbody>
  </Table>
)

type WorkspaceFilterProps = {
  filter: WorkspaceFilter
  onChange: ({ tier, name, flag }: WorkspaceFilter) => void
}

const WorkspaceFilterView = ({ onChange, filter }: WorkspaceFilterProps) => {
  const [workspaceId, setWorkspaceId] = React.useState('')
  const [formValues, setFormValues] = React.useState<WorkspaceFilter>({
    name: filter.name ?? '',
    tier: filter.tier,
    flag: filter.flag,
    accountStatus: filter.accountStatus,
    scriptFormatId: filter.scriptFormatId ?? '',
  })

  const { isError, data: betaFlags } = useFetchBetaFlags()

  if (isError) showError('Failed to fetch beta flags')

  const handleSubmit = (e: StoppableEvent) => {
    e.preventDefault()
    onChange(formValues)
  }

  const clearForm = (e: StoppableEvent) => {
    e.preventDefault()
    const newValues = {
      name: '',
      tier: undefined,
      flag: undefined,
      accountStatus: undefined,
      scriptFormatId: undefined,
    }
    setFormValues(newValues)
    setWorkspaceId('')
    onChange(newValues)
  }

  const handleSelectChange = (filter: WorkspaceFilter) => {
    const newValues = { ...formValues, ...filter }
    setFormValues(newValues)
    onChange(newValues)
  }

  const activeFilters: string[] = [
    filter.flag ? `with feature flag "${filter.flag}"` : '',
    filter.name ? `matching name: "${filter.name}"` : '',
    filter.tier ? `on tier: "${filter.tier}"` : '',
    filter.accountStatus
      ? `with account status: "${filter.accountStatus}"`
      : '',
    filter.scriptFormatId
      ? `using script format: "${filter.scriptFormatId}"`
      : '',
  ].filter((f) => f)
  const filterMessage =
    activeFilters.length > 0
      ? `Showing workspaces ${activeFilters.join(', ')}`
      : ''

  const allFlagNames = betaFlags?.map((f) => f.name) ?? []
  const hasFlagFilter = !!formValues.flag

  const isMax = (acc: number, curr: string) => Math.max(acc, curr.length)
  const longestFlagCharCount = allFlagNames.reduce(isMax, 0) || 5

  return (
    <>
      <h1>
        <NavAnchor href="/staff">Staff Zone</NavAnchor>
      </h1>
      <form onSubmit={handleSubmit}>
        <Stack>
          <Group>
            <TextInput
              label="Workspace name"
              placeholder={hasFlagFilter ? '' : 'The Late Show'}
              value={formValues.name}
              disabled={hasFlagFilter}
              onChange={(e) =>
                setFormValues({ ...formValues, name: e.target.value })
              }
            />
            <TextInput
              label="Default script format"
              placeholder={hasFlagFilter ? '' : 'variety-2025-01-11'}
              value={formValues.scriptFormatId}
              disabled={hasFlagFilter}
              onChange={(e) =>
                setFormValues({ ...formValues, scriptFormatId: e.target.value })
              }
            />
          </Group>
          <Group>
            <Select
              allowDeselect
              classNames={{ input: styles.select_input }}
              label="Tier"
              placeholder="all"
              inputSize="10"
              value={formValues.tier ?? null}
              data={schemas.WorkspaceTier.options}
              disabled={hasFlagFilter}
              onChange={(tier) =>
                handleSelectChange({ tier: tier ?? undefined })
              }
            />
            <Select
              allowDeselect
              classNames={{ input: styles.select_input }}
              label="Flag"
              placeholder="all"
              value={formValues.flag ?? null}
              data={allFlagNames}
              inputSize={String(longestFlagCharCount)}
              disabled={allFlagNames.length < 1}
              onChange={(flag) =>
                handleSelectChange({ flag: flag ?? undefined })
              }
            />
            <Select
              classNames={{ input: styles.select_input }}
              label="Status"
              placeholder="all"
              inputSize="10"
              value={formValues.accountStatus ?? null}
              data={schemas.WorkspaceAccountStatus.options}
              allowDeselect
              onChange={(val) => {
                const accountStatus =
                  (val as WorkspaceAccountStatus) ?? undefined
                handleSelectChange({ accountStatus })
              }}
              disabled={hasFlagFilter}
            />
          </Group>
        </Stack>
        {filterMessage && <p>{filterMessage}</p>}
        <Space h={20} />
        <Group justify="center">
          <Button type="submit">Search</Button>
          <Button variant="subtle" onClick={clearForm}>
            Reset Filters
          </Button>
        </Group>
        <Space h={20} />
        <Group justify="flex-end">
          <TextInput
            placeholder="48f4cc7b-bd3..."
            value={workspaceId}
            inputSize="12"
            onChange={(e) => setWorkspaceId(e.target.value)}
          />
          <NavAnchor
            href={`/staff/workspaces/${isUUID(workspaceId) ? workspaceId : ''}`}
          >
            <Text span size="18" c={isUUID(workspaceId) ? 'violet' : 'gray'}>
              <FaIcon icon="fa-hand-point-right" />
            </Text>
          </NavAnchor>
        </Group>
      </form>
    </>
  )
}

export const Workspaces = () => {
  const { scrapi, location } = useMst()
  // when we wire up UI for filtering we can make this bi-directional
  const flag: string | undefined = location.getQueryParam('flag')
  const filter = { flag }

  const findWorkspacesForStaff = async ({
    from,
    size,
    name,
    tier,
    flag,
    accountStatus,
    scriptFormatId,
  }: ZInfer<typeof schemas.FindWorkspacesRequest>) => {
    const response = await scrapi.staff.findWorkspaces({
      body: { from, size, name, tier, flag, accountStatus, scriptFormatId },
    })

    if (response.status !== 200) throw new Error('oops')
    return response.body
  }

  return (
    <PaginatedPayloadView
      getPage={findWorkspacesForStaff}
      FilterView={WorkspaceFilterView}
      pageSize={20}
      ResultView={WorkspacesResultsView}
      initialFilter={filter}
    />
  )
}
