import { Portal, Tooltip, UnstyledButton } from '@mantine/core'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'

import { CustomIcon } from '@components/CustomIcon'
import { IPopulatedPmEditor, useMst } from '@state'

import styles from './InlineElementMenuButton.module.scss'

export const InlineElementMenuButton = observer(
  function InlineElementMenuButton({
    icon,
    observableEditor,
    onClick,
    isPageless,
  }: {
    icon: `icon-${string}`
    observableEditor: IPopulatedPmEditor
    onClick: () => void
    isPageless: boolean
  }) {
    const { view } = useMst()
    const { editorState, editorView } = observableEditor
    const blockStartPos = editorState.selection.$from.start(2)
    const blockTop = editorView.coordsAtPos(blockStartPos).top
    const editorTop = editorView.dom.getBoundingClientRect().top
    const top = (blockTop - editorTop) / view.editorZoom

    return (
      <Portal target="#pm-host-wrapper">
        <Tooltip position="right" label="Tab or click to change element">
          <UnstyledButton
            tabIndex={0}
            className={cn(styles.elementMenu, {
              [styles.pageless]: isPageless,
            })}
            style={{ top }}
            onClick={onClick}
          >
            <CustomIcon icon={icon} className={styles.elementMenu_icon} />
          </UnstyledButton>
        </Tooltip>
      </Portal>
    )
  },
)
