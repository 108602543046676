import React from 'react'

import { Select, Stack, Text, TextInput } from '@mantine/core'
import cn from 'classnames'

import {
  SlotConfig,
  slotContentType,
  StaticSlotContent,
} from '@util/zodSchemas/printPreferences'

import styles from './SlotSelect.module.scss'

const slotData: Array<{
  value: StaticSlotContent | 'custom'
  label: string
}> = [
  { value: 'timestamp', label: 'Date and time' },
  { value: 'page-number', label: 'Page number' },
  { value: 'page-number-total', label: 'Page number/total' },
  {
    value: 'duration',
    label: 'Script duration',
  },
  { value: 'short-title', label: 'Title' },
  {
    value: 'full-title',
    label: 'Title and section info',
  },
  {
    value: 'custom',
    label: 'Custom text',
  },
]

export const SlotSelect = ({
  label,
  config,
  setContent,
  disabled,
  inactive = false,
}: {
  label: string
  config?: SlotConfig
  setContent: (value: SlotConfig | undefined) => void
  disabled?: boolean
  inactive?: boolean
}) => {
  const content =
    config?.slotType === 'literal'
      ? 'custom'
      : config?.slotType === 'static'
        ? config.contentType
        : undefined

  const [customText, setCustomText] = React.useState(
    config?.slotType === 'literal' ? config.text : '',
  )

  const setSlotType = (value: string | null) => {
    if (value === 'custom') {
      setContent({
        slotType: 'literal',
        text: '',
      })
    } else {
      const parsed = slotContentType.safeParse(value)
      if (parsed.success) {
        setContent({
          slotType: 'static',
          contentType: parsed.data,
        })
      } else {
        setContent(undefined)
      }
    }
  }

  const submitCustomText = () => {
    if (config?.slotType === 'literal') {
      setContent({
        slotType: 'literal',
        text: customText,
      })
    }
  }

  return (
    <Stack gap={8} className={styles.slotSelect_stack}>
      <Text fw="bold">{label}</Text>
      <Select
        classNames={{
          input: cn(styles.slotSelect_input, {
            [styles.slotSelect_input___inactive]: inactive,
          }),
        }}
        clearable
        disabled={disabled}
        value={content}
        onChange={setSlotType}
        data={slotData}
        // we set this value manually in order to make the input clearable
        // once https://github.com/mantinedev/mantine/issues/7395 is resolved
        // this shouldn't be necessary anymore
        rightSectionPointerEvents="inherit"
      />
      {config?.slotType === 'literal' && (
        <TextInput
          placeholder="Your custom text"
          size="sm"
          value={customText}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              submitCustomText()
            }
          }}
          onChange={(e) => setCustomText(e.target.value)}
          onBlur={submitCustomText}
        />
      )}
    </Stack>
  )
}
