import printJS from 'print-js-updated'

import { IRoot } from '@state/types'
import { ScriptFormatConfiguration } from '@util/formats'
import { getMergedFormatDefinition } from '@util/prosemirrorHelpers'
import { RundownRowData, ScriptJson } from '@util/ScriptoApiClient/types'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { saveBlobToFile, saveTextToFile } from '../fileDownload'
import { buildPrintableScriptHtml } from '../princePrinting'

export const getBlobAndSave = async ({
  downloadFn,
  fileName = 'Untitled',
}: {
  downloadFn: () => Promise<Blob | undefined>
  fileName?: string
}) => {
  const blob = await downloadFn()
  if (!blob) return
  saveBlobToFile({ blob, fileName })
}

export const getBlobAndPrint = async (
  downloadFn: () => Promise<Blob | undefined>,
) => {
  const blob = await downloadFn()
  if (!blob) return
  const href = URL.createObjectURL(blob)
  printJS({ printable: href, type: 'pdf' })
  URL.revokeObjectURL(href)
}

export const getPdfBlobForScript = async ({
  scriptId,
  title,
  mst,
  rundownRowData,
}: {
  scriptId: string
  title: string
  mst: IRoot
  rundownRowData?: RundownRowData
}) => {
  const { apiClient, user, view, currentOrg } = mst
  const unwatermark = view.shouldUnwatermark
  const printPrefs = user.scriptPrintPrefs

  const { id: snapshotId } = await apiClient.createSnapshot({
    scriptId,
    autoSave: 'export',
  })

  const { doc, scriptFormat } = await apiClient.getSnapshot({
    scriptId,
    snapshotId,
  })
  const formatDefinition = getMergedFormatDefinition(
    doc,
    scriptFormat.definition,
  )
  const html = buildPrintableScriptHtml({
    prefs: printPrefs,
    title,
    readRate: currentOrg?.readRate,
    rundownRowData,
    doc,
    formatDefinition,
  })

  // DEV HACK: For debugging html generation
  // saveTextToFile({ text: html, fileName: 'test.html' })

  return apiClient.passThroughPrint({
    html,
    fileName: `${title}.pdf`,
    unwatermark,
  })
}

export const getPdfBlobForSection = async ({
  doc,
  title,
  mst,
  formatDefinition,
}: {
  doc: ScriptJson
  title: string
  mst: IRoot
  formatDefinition: ScriptFormatConfiguration
}) => {
  const { apiClient, user, view, currentOrg } = mst
  const unwatermark = view.shouldUnwatermark
  const printPrefs = user.scriptPrintPrefs

  const html = buildPrintableScriptHtml({
    prefs: printPrefs,
    title,
    readRate: currentOrg?.readRate,
    doc,
    formatDefinition,
  })

  return apiClient.passThroughPrint({
    html,
    fileName: `${title}.pdf`,
    unwatermark,
  })
}
