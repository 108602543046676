import { applyPatch, getPath, types } from 'mobx-state-tree'

import { OrgOption, OrgOptionMap, OrgTierMap } from '@showrunner/codex'
import { schemas, ZInfer } from '@showrunner/scrapi'

import {
  IInvite,
  ILoadedScript,
  IOrgMember,
  IRundown,
  IRundownListing,
  IScriptListing,
  PermittedMembers,
} from '@state/types'
import { getAvatarUrl, saveTextToFile } from '@util'
import { MyHistory } from '@util/LocalPersistence'
import { OrgMemberPayload, WorkspaceTier } from '@util/ScriptoApiClient/types'

import { BaseModel } from '../BaseModel'
import { Invite, InviteStatus } from '../Invite'
import { OrgMember } from '../OrgMember'
import { ScriptFormatSummaryModel } from '../ScriptFormats'
import { WorkspaceEventPayload } from '../SocketManager/helpers'
import { GET_LISTINGS_PAGE_SIZE } from '../util'

export type MemberRole = 'Owner' | 'Admin' | 'Contributor'
export type ModernScriptDocType = ZInfer<typeof schemas.ModernScriptDocType>

const { PRIVATE_SCRIPTS, PROMPTER_INTEGRATION, RUNDOWNS, SCRIPT_LIMITING } =
  OrgOptionMap

export const OrgOptionModel = types.model('OrgOption', {
  code: types.enumeration<OrgOption>([...Object.values(OrgOptionMap)]),
  enabled: types.boolean,
  staffManaged: types.boolean,
})

export const Org = BaseModel.named('Org')
  .props({
    id: types.string,
    name: types.string,
    loading: true,
    betaFlags: types.array(types.string),
    members: types.array(OrgMember),
    invites: types.array(Invite),
    owner: OrgMember,
    options: types.array(OrgOptionModel),
    studioFormat: ScriptFormatSummaryModel,
    screenplayFormat: ScriptFormatSummaryModel,
    tier: types.maybe(
      types.enumeration<WorkspaceTier>(Object.values(OrgTierMap)),
    ),
    isUpdating: false,
    rundownSchemaName: types.string,
    favoriteItemIds: types.frozen<string[]>([]),
    myHistory: types.frozen<MyHistory['listings']>([]),
    readRate: types.maybe(types.number),
    // accountStatus and templateCode are irrelevant to wallaby, just used
    // for analytics, so we just set and forget them
    accountStatus: types.string,
    templateCode: types.maybe(types.maybeNull(types.string)),
  })
  .views((self) => ({
    get hasRundownsEnabled(): boolean {
      return !!self.options.find(({ code }) => code === RUNDOWNS)?.enabled
    },
    get alphabetizedMembers(): IOrgMember[] {
      return self.members.slice().sort((a, b) => a.name.localeCompare(b.name))
    },
    get openInvites(): IInvite[] {
      return self.invites
        .filter((i) => i.status === 'open')
        .sort((a, b) => a.email.localeCompare(b.email))
    },
    get hasPrivateScriptsEnabled(): boolean {
      return !!self.options.find(({ code }) => code === PRIVATE_SCRIPTS)
        ?.enabled
    },
    get hasPrompterAvailable(): boolean {
      return self.options.some((o) => o.code === PROMPTER_INTEGRATION)
    },
    get hasPrompterDisabled(): boolean {
      return (
        this.hasPrompterAvailable &&
        !self.options.find(({ code }) => code === PROMPTER_INTEGRATION)?.enabled
      )
    },
    get hasLimitedScriptsEnabled(): boolean {
      return !!self.options.find(({ code }) => code === SCRIPT_LIMITING)
        ?.enabled
    },
    get isUnpaid(): boolean {
      return self.tier === OrgTierMap.FREE
    },
    get myHistoryItemUuids(): string[] {
      return self.myHistory.map(([, listingId]) => listingId)
    },
    get studioFormatName(): string {
      // ie: omit trailing 'script' from name for parity. ie: SNL, Classic Studio, Studio
      return self.studioFormat.name.replace(/script$/i, '')
    },
    isFavorite(itemId: string) {
      return self.favoriteItemIds.includes(itemId)
    },
    permittedMembers(permissionCode: string): PermittedMembers {
      return this.alphabetizedMembers
        .filter((m) => m.permissions.includes(permissionCode))
        .map((user) => {
          return {
            id: user.id,
            name: user.name,
            email: user.email,
            image: getAvatarUrl(user.avatar, self.rootStore.environment.config),
          }
        })
    },
    // helpers for socket events
    getRundownAndListing(rundownId: number): {
      rundown?: IRundown
      listing?: IRundownListing
    } {
      const { currentRundown, rundownMap } = self.rootStore
      return {
        rundown: currentRundown?.id === rundownId ? currentRundown : undefined,
        listing: rundownMap.get(String(rundownId)),
      }
    },
    getScriptAndListing(scriptId: string): {
      script?: ILoadedScript
      listing?: IScriptListing
    } {
      const { currentScript, scriptMap } = self.rootStore
      return {
        script: currentScript?.id === scriptId ? currentScript : undefined,
        listing: scriptMap.get(scriptId),
      }
    },
  }))
  .actions((self) => ({
    setName(newName: string) {
      self.name = newName
    },
    setOwner(newOwner: OrgMemberPayload) {
      self.owner = OrgMember.create(newOwner)
    },
    setIsUpdating(value: boolean) {
      self.isUpdating = value
    },
    // patching (as opposed to a wholesale members.replace) helps us avoid
    // referencing a stale object in the mobx tree from the document pane afterward
    patchMemberPermissions(memberId: string, value: string[]) {
      const member = self.members.find((m) => m.id === memberId)
      if (!member) return
      applyPatch(self.rootStore, {
        op: 'replace',
        path: getPath(member.permissions),
        value,
      })
    },
    setMembers(members: OrgMemberPayload[]) {
      self.members.replace(members.map((m) => OrgMember.create(m)))
    },
    setInvites(invites: { id: string; email: string; status: InviteStatus }[]) {
      self.invites.replace(invites.map((i) => Invite.create(i)))
    },

    // Look up the prosemirror docType associated with the script format. To
    // date, this has been by convention, but callers should be aware that if a
    // custom script format uses another docType, it's not captured in this
    // default and that codepaths that make use of this function should instead
    // pass the docType explicitly. More info in this comment:
    //
    // https://github.com/showrunner/wallaby/pull/3331#pullrequestreview-2466151546
    //
    docTypeForFormat(formatId: string): ModernScriptDocType {
      switch (formatId) {
        case self.screenplayFormat.id:
          return 'screenplay'
        case self.studioFormat.id:
          return 'variety'
        default: {
          const msg = `Invariant: docType undefined for format ${formatId}`
          self.log.error(msg)
          throw new Error(msg)
        }
      }
    },

    // called after we reconnect to try to catch up on workspace
    // messages we mist
    async refreshRecentlyUpdated() {
      const pageSize = GET_LISTINGS_PAGE_SIZE
      const result = await self.scrapi.workspaces.listItems({
        params: { id: self.id },
        body: {
          paging: {
            orderBy: 'updatedAt',
            direction: 'desc',
            pageSize,
          },
        },
      })

      if (result.status === 200) {
        self.rootStore.ingestWorkspaceItems(result.body.data)
      }

      return result
    },

    async refreshRecentListings() {
      const pageSize = self.rootStore.view.listingsPageSize
      const result = await self.scrapi.workspaces.listItems({
        params: { id: self.id },
        body: {
          paging: {
            orderBy: 'contentsModifiedAt',
            direction: 'desc',
            pageSize,
          },
          filter: {
            itemTypes: ['script', 'rundown'],
          },
        },
      })

      if (result.status === 200) {
        self.rootStore.ingestWorkspaceItems(result.body.data)
      }

      return result
    },

    // This gets us recently updated listings-- right now this is being
    // used when we find out there's a document missing from the scriptMap
    // or rundownMap (because the socket payload isn't enough to hydrate
    // the listing). We can use it for patching data after disconnect as well
    async refreshFolderItems(parentFolderId: string) {
      const result = await self.scrapi.folders.listItems({
        params: { id: parentFolderId },
        body: {
          paging: {
            orderBy: 'updatedAt',
            direction: 'desc',
            pageSize: 50,
          },
          filter: {
            itemTypes: ['script', 'rundown'],
          },
        },
      })
      if (result.status === 200) {
        self.rootStore.ingestWorkspaceItems(result.body.data)
      }
    },
    // if a listing shows up that didn't exist or if it's folder
    // changed, we need to refresh some counts/contents
    handleDocListingSocketUpdate(
      payload: {
        name: string
        contentsModifiedAt: Date
        contentsModifiedBy: string
        folderId: string
      },
      listing?: IScriptListing | IRundownListing,
    ) {
      // if listing doesn't exist refresh the folder it belongs to
      if (!listing) {
        this.refreshFolderItems(payload.folderId)
        return
      }

      // if there's a folder change, we have extra work to do, so
      // save off the info before we update the listing
      const oldFolderId = listing.folderId
      const newFolderId = payload.folderId

      listing.updateFields(payload)

      if (oldFolderId !== newFolderId) {
        listing.setFolderId(newFolderId)
        const { currentFolder } = self.rootStore
        if (
          currentFolder &&
          [oldFolderId, newFolderId].includes(currentFolder.id)
        ) {
          currentFolder.refreshDetails()
        }
      }
    },

    handleSocketMessage(payload: WorkspaceEventPayload) {
      switch (payload.eventType) {
        case 'FOLDER_LISTING_UPDATED': {
          const folder = self.rootStore.folderMap.get(payload.folderId)
          const parentFolder = self.rootStore.folderMap.get(payload.parentId)
          if (folder && parentFolder) {
            folder.updateFields(payload)
          } else if (parentFolder) {
            self.rootStore.loadFolderSummary(payload.folderId)
          } else {
            // if we're missing the parent folder, just give up and
            // get ALL the folders for the current workspace
            self.rootStore.loadFolderTree(self.id)
          }
          break
        }
        case 'RUNDOWN_LISTING_UPDATED': {
          const { rundown, listing } = self.getRundownAndListing(
            payload.rundownId,
          )
          rundown?.updateFields(payload)
          rundown?.setFolderId(payload.folderId)
          this.handleDocListingSocketUpdate(payload, listing)
          break
        }
        case 'SCRIPT_LISTING_UPDATED': {
          const { script, listing } = self.getScriptAndListing(payload.scriptId)
          script?.updateFields(payload)
          script?.setSharedStatus(payload.status)
          script?.setFolderId(payload.folderId)
          this.handleDocListingSocketUpdate(payload, listing)
          listing?.setSharedStatus(payload.status)
          break
        }
      }
    },
    updateFavorites(favorites: Array<string>) {
      self.favoriteItemIds = favorites
      self.environment.localPersistence.setFavoriteListings({
        userId: self.rootStore.user.id,
        orgId: self.id,
        favorites,
      })
    },
    addFavorite(uuid: string) {
      const newFavorites = [uuid, ...self.favoriteItemIds].slice(0, 100)
      this.updateFavorites(newFavorites)
    },
    removeFavorite(itemId: string) {
      this.updateFavorites(self.favoriteItemIds.filter((id) => id !== itemId))
    },
    refreshFavorites() {
      self.favoriteItemIds =
        self.environment.localPersistence.getFavoriteListings({
          userId: self.rootStore.user.id,
          orgId: self.id,
        })
    },
    getMember(userId: string): IOrgMember | undefined {
      return self.members.find((u) => u.id === userId)
    },
    setMyHistory(val: MyHistory) {
      self.myHistory = val.listings
    },
  }))
  // async actions
  .actions((self) => ({
    async createScript(script: {
      folderId: string
      formatId: string
      docType?: ModernScriptDocType
      name?: string
    }): Promise<{ id: string; docType: ModernScriptDocType }> {
      const { folderId, formatId, name = 'Untitled' } = script
      const docType = script.docType ?? self.docTypeForFormat(formatId)
      const { id } = await self.rootStore.createScriptAndUpdateMap({
        name,
        folderId,
        formatId,
        docType,
      })
      return { id, docType }
    },

    async update({ name, ownerId }: { name?: string; ownerId?: string }) {
      self.setIsUpdating(true)
      try {
        await self.rootStore.doDebug()
        const response = await self.apiClient.updateOrg({
          orgId: self.id,
          name,
          ownerId,
        })
        if (self.name !== response.name) {
          self.setName(response.name)
          self.rootStore.user.selectedMembership?.setName(response.name)
          self.trackEvent('ORG_RENAMED')
        }
        if (self.owner.id !== response.owner.id) {
          self.setOwner(response.owner)
          self.trackEvent('ORG_OWNERSHIP_TRANSFER')
        }
        self.setMembers(response.members)
      } finally {
        self.setIsUpdating(false)
      }
    },

    async upgradeInquiry() {
      await self.apiClient.orgUpgradeInquiry({ orgId: self.id })
      self.trackEvent('UPGRADE_INQUIRY_SENT')
    },

    async createInvite(email: string) {
      await self.apiClient.createOrgInvite({
        orgId: self.id,
        email,
      })
    },

    async grantMemberPermission({
      userId,
      permissionCode,
    }: {
      userId: string
      permissionCode: string
    }) {
      const response = await self.apiClient.grantMemberPermission({
        orgId: self.id,
        userId,
        permissionCode,
      })
      const member = response.members.find((m) => m.id === userId)
      if (member) self.patchMemberPermissions(member.id, member.permissions)

      return response
    },

    async revokeMemberPermission({
      userId,
      permissionCode,
    }: {
      userId: string
      permissionCode: string
    }) {
      const response = await self.apiClient.revokeMemberPermission({
        orgId: self.id,
        userId,
        permissionCode,
      })
      const member = response.members.find((m) => m.id === userId)
      if (member) self.patchMemberPermissions(member.id, member.permissions)

      return response
    },

    async refreshMyHistory() {
      await self.rootStore.doDebug()
      self.setMyHistory(
        await self.environment.localPersistence.getMyHistory({
          userId: self.rootStore.user.id,
          orgId: self.id,
        }),
      )
    },

    async updateMyHistory(uuid: string) {
      await self.rootStore.doDebug()
      // put the most recent item at the front of the list,
      // removing any other instances of it. This lets us
      // get the most recent 50 opened docs by keeping them
      // sorted with the newest up front
      const fullList: MyHistory['listings'] = [
        [new Date(), uuid],
        ...self.myHistory.filter(([, itemId]) => itemId !== uuid),
      ]

      const trimmedList = fullList.slice(0, 50)
      self.setMyHistory({ listings: trimmedList })
      await self.environment.localPersistence.setMyHistory({
        userId: self.rootStore.user.id,
        orgId: self.id,
        myHistory: { listings: trimmedList },
      })
    },

    async exportSnapshotToFdx({
      scriptId,
      snapshotId,
    }: {
      scriptId: string
      snapshotId: string
    }) {
      await self.rootStore.doDebug()
      const { fileName, text, contentType } = await self.apiClient.exportFdx({
        scriptId,
        snapshotId,
      })
      saveTextToFile({ text, fileName, contentType })
      self.trackEvent('BETA_EXPORTED_SNAPSHOT_FDX', { scriptId })
    },
  }))
  .actions((self) => ({
    // set a superproperty in mixpanel so that all subsequent events
    // are tracked with the correct workspace id and name
    afterAttach() {
      self.analytics.setPersistentEventProperties({
        workspaceId: self.id,
        workspaceName: self.name,
        accountStatus: self.accountStatus,
        workspaceTemplate: self.templateCode,
      })

      self.refreshFavorites()
      self.refreshMyHistory()
      self.refreshRecentListings()
    },
  }))
