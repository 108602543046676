import { renderToString } from 'react-dom/server'

import { generateThemeVariables } from '@theme'
import * as cssStrings from '@util/printing/cssStrings'
import {
  PageLayout,
  ScriptPrintPreferences,
} from '@util/zodSchemas/printPreferences'

import { buildHtmlForPrince } from './buildHtmlForPrince'
import { buildPageCssVars } from './buildPageCssVars'
import * as constants from './constants'

type SideBySidePrintStrategyParams = {
  html: string
  headerHtml: string
  timestamp: string
  title: string
  prefs: ScriptPrintPreferences
}

class SideBySidePrintStrategy {
  protected html: string
  protected headerHtml: string
  protected timestamp: string
  protected title: string
  protected prefs: ScriptPrintPreferences

  constructor(params: SideBySidePrintStrategyParams) {
    this.html = params.html
    this.headerHtml = params.headerHtml
    this.timestamp = params.timestamp
    this.title = params.title
    this.prefs = params.prefs
  }

  margins = constants.SIDE_BY_SIDE_MARGIN_SIZE

  generateHeadElements(): string {
    return constants.FONTS
  }

  // this combines styles common to all script print html with
  // the format-specific ones provided by the subclass
  generateStyles() {
    return [
      generateThemeVariables(),
      buildPageCssVars({
        layout: this.generatePageLayout(),
        title: this.title,
        timestamp: this.timestamp,
        // we dont honor monochrome print prefs for side by side
        // because there is a corresponding screen pref
      }),
      cssStrings.headerFooterCSS,
      cssStrings.sideBySideCSS,
      cssStrings.editorFontCSS,
      cssStrings.sideBySidePrintCSS,
    ].join('\n')
  }

  generateBody() {
    return this.html
  }

  generatePageLayout(): PageLayout {
    return {
      size: { height: '11in', width: '8.5in' },
      margins: this.margins,
      orientation: 'landscape',
    }
  }
  // for this layout specifically we pull a header from the guts of the side by side screen display
  // and draw page numbers in the footer declared using a simple prince counter in SideBySidePrint.scss
  // passing html inside the <header/> requires that we override the users active print preferences
  // (to ensure it is enabled on each page) but the page count in the footer displays even without an override
  generateHeaderAndFooter() {
    return renderToString(
      <header dangerouslySetInnerHTML={{ __html: this.headerHtml }} />,
    )
  }
}

export const buildPrintableDiffHtml = (
  params: SideBySidePrintStrategyParams,
): string => {
  const strategy = new SideBySidePrintStrategy(params)
  return buildHtmlForPrince(strategy)
}
