import React from 'react'

import NiceModal from '@ebay/nice-modal-react'
import { Button, Checkbox, Fieldset, JsonInput } from '@mantine/core'

import { schemas } from '@showrunner/scrapi'

import { ModalShell, useModalControls } from '@components/Modals'
import { safeParseJSON } from '@util'
import {
  BlockOverrides,
  PageConfig,
  ScriptFormatBlockConfiguration,
} from '@util/formats'
import { LAYOUT_PRESETS, LayoutPreset } from '@util/PageLayout'
import { Unit } from '@util/Unit'

type Props = {
  blockFormats: ScriptFormatBlockConfiguration
  pageConfig: PageConfig
  onConfirm: Callback<
    [
      {
        pageConfig: PageConfig
        blockOverrides?: BlockOverrides
      },
    ]
  >
}

// This generates a new leftMargin and width value for each block to make the right and left
// margins stay in the same place
const createBlockOverrides = ({
  oldBlocks,
  oldConfig,
  newConfig,
}: {
  oldBlocks: ScriptFormatBlockConfiguration
  oldConfig: PageConfig
  newConfig: PageConfig
}): BlockOverrides => {
  const oldLeftMarginCh = new Unit(oldConfig.marginLeft, oldConfig.unit).toCh()
    .value
  const oldPageWidthCh = new Unit(oldConfig.width, oldConfig.unit).toCh().value
  const newLeftMarginCh = new Unit(newConfig.marginLeft, newConfig.unit).toCh()
    .value
  const newPageWidthCh = new Unit(newConfig.width, newConfig.unit).toCh().value

  const leftMarginAdjust = oldLeftMarginCh - newLeftMarginCh
  const widthAdjust = newPageWidthCh - oldPageWidthCh

  const result: BlockOverrides = {}
  schemas.scriptFormats.AllBlocks.options.forEach((blockName) => {
    // Don't touch the dual dialogue columns
    if (blockName !== 'dual_dialogue_column') {
      result[blockName] = {
        marginLeft: oldBlocks[blockName].marginLeft + leftMarginAdjust,
        width: oldBlocks[blockName].width + widthAdjust,
      }
    }
  })
  return result
}

const ModalInternal = (props: Props) => {
  const [jsonStr, setJsonStr] = React.useState(
    JSON.stringify(props.pageConfig, null, 2),
  )
  const [adjustSideMargins, setAdjustSideMargins] = React.useState(true)
  const [errorMessage, setErrorMessage] = React.useState('')

  const controls = useModalControls()
  const { onConfirm } = props

  const handleConfirm = () => {
    const rawObj = safeParseJSON(jsonStr)
    // if we don't hae a rawObj, the mantine control will already
    // show an error
    if (rawObj) {
      const parsed = schemas.scriptFormats.PageConfig.safeParse(rawObj)
      if (parsed.success) {
        const newConfig = parsed.data
        const blockOverrides = adjustSideMargins
          ? createBlockOverrides({
              oldBlocks: props.blockFormats,
              oldConfig: props.pageConfig,
              newConfig,
            })
          : undefined

        onConfirm({
          pageConfig: newConfig,
          blockOverrides,
        })
        controls.onClose()
      } else {
        setErrorMessage('Invalid configuration, see console for details')
        // eslint-disable-next-line no-console
        console.error(parsed.error.issues)
      }
    }
  }

  const handleCancel = () => {
    controls.onClose()
  }

  const selectPreset = (key: LayoutPreset) => {
    const config = LAYOUT_PRESETS[key]
    setJsonStr(JSON.stringify(config, null, 2))
  }

  return (
    <ModalShell
      title="Edit Page Layout"
      confirmLabel="Save"
      cancelLabel="Cancel"
      size={400}
      onConfirm={handleConfirm}
      opened={controls.opened}
      onClose={handleCancel}
    >
      <JsonInput
        styles={{
          // Remove this once we refactor input.module.css to apply styles to
          // inputs more selectively:
          input: {
            fontFamily: 'var(--mantine-font-family-monospace)',
          },
        }}
        formatOnBlur
        autosize
        minRows={10}
        validationError="Invalid JSON"
        value={jsonStr}
        onChange={setJsonStr}
        error={errorMessage}
      />
      <Fieldset legend="Quick fill with preset">
        <Button.Group>
          <Button onClick={() => selectPreset('DEFAULT')} variant="default">
            Current
          </Button>
          <Button onClick={() => selectPreset('PROPOSED')} variant="default">
            Proposed
          </Button>
          <Button onClick={() => selectPreset('A4')} variant="default">
            A4
          </Button>
          <Button onClick={() => selectPreset('WEIRDO')} variant="default">
            Weird
          </Button>
        </Button.Group>
      </Fieldset>
      <Checkbox
        label="Adjust left margin and width"
        checked={adjustSideMargins}
        onChange={(e) => setAdjustSideMargins(e.target.checked)}
      />
    </ModalShell>
  )
}

const PageLayoutModal = NiceModal.create<Props>(ModalInternal)

export const showPageLayoutModal = (props: Props) => {
  return NiceModal.show<boolean>(PageLayoutModal, props)
}
