import { ActionIcon, Anchor, Group, Menu, Tooltip } from '@mantine/core'

import { FaIcon } from '@components/FaIcon'
import { FancyMenuItem } from '@components/FancyMenuItem'
import { showShortcutsModal } from '@components/Modals'
import { WHATS_NEW_URL } from '@components/Modals/WhatsNew'
import { useMst } from '@state'

import env from '../../../choo-app/lib/env.js'

import { WhatsNewBlurb } from './WhatsNewBlurb'

import styles from './HelpMenu.module.scss'

const { browser } = env

type HelpMenuItemType =
  | 'intercom'
  | 'help'
  | 'whatsnew'
  | 'shortcuts'
  | 'divider'
  | 'apidocs'

type HelpMenuItem = {
  key: HelpMenuItemType
  label: string
  icon: `fa-${string}` | ''
  href?: string
}

const itemMap: Record<HelpMenuItemType, HelpMenuItem> = {
  intercom: {
    key: 'intercom',
    label: 'Live support',
    icon: 'fa-commenting',
  },
  whatsnew: {
    key: 'whatsnew',
    label: "What's new",
    icon: 'fa-gift',
    href: WHATS_NEW_URL,
  },
  help: {
    key: 'help',
    label: 'Help center',
    icon: 'fa-info-circle',
    href: 'https://help.scripto.live/',
  },
  apidocs: {
    key: 'apidocs',
    label: 'API docs',
    icon: 'fa-code',
    href: 'https://developer.scripto.live/',
  },
  divider: {
    key: 'divider',
    label: '',
    icon: '',
  },
  shortcuts: {
    key: 'shortcuts',
    label: 'Keyboard shortcuts',
    icon: 'fa-keyboard',
  },
}

const ALL_ITEMS = [
  itemMap.intercom,
  itemMap.whatsnew,
  itemMap.help,
  itemMap.apidocs,
  itemMap.divider,
  itemMap.shortcuts,
]
const NON_EDITOR_ITEMS = [
  itemMap.intercom,
  itemMap.help,
  itemMap.whatsnew,
  itemMap.apidocs,
]

export function HelpMenu({ showShortcuts }: { showShortcuts: boolean }) {
  const mst = useMst()
  const orderedItems = showShortcuts ? ALL_ITEMS : NON_EDITOR_ITEMS

  const handleClick = (key: HelpMenuItemType) => {
    if (key === 'intercom') {
      const intercom = window.Intercom
      if (typeof intercom === 'function') {
        intercom('show')
      }
    }
  }

  return (
    <Menu shadow="xl" withinPortal position="right-end">
      <Menu.Target>
        <Tooltip
          label="Help, what's new, keyboard shortcuts"
          position="right"
          withinPortal
        >
          <ActionIcon color="dark" variant="transparent">
            <FaIcon icon="fa-question-circle" />
          </ActionIcon>
        </Tooltip>
      </Menu.Target>
      <Menu.Dropdown>
        <WhatsNewBlurb />
        {orderedItems.map((item) => {
          if (item.key === 'divider') return <Menu.Divider key={item.key} />
          if (item.key === 'shortcuts') {
            return (
              <FancyMenuItem
                onClick={() =>
                  showShortcutsModal({
                    scriptType: mst.currentScript?.type,
                    isSafari: browser.safari,
                  })
                }
                key={item.key}
                iconClass={`fa ${item.icon}`}
                title={item.label}
              ></FancyMenuItem>
            )
          }

          return item.href ? (
            <Menu.Item key={item.key}>
              <Anchor
                className={styles.helpMenu_link}
                target="_blank"
                rel="noreferrer"
                href={item.href}
              >
                <Group gap={10}>
                  <FaIcon
                    fixedWidth
                    icon={item.icon as `fa-${string}`}
                    size="sm"
                  />
                  {item.label}
                </Group>
              </Anchor>
            </Menu.Item>
          ) : (
            <FancyMenuItem
              key={item.key}
              iconClass={`fa ${item.icon}`}
              onClick={() => handleClick(item.key)}
              title={item.label}
            ></FancyMenuItem>
          )
        })}
      </Menu.Dropdown>
    </Menu>
  )
}
